import React, { useState, useRef, useEffect } from "react";
import menuPolygonIcon from "../../../Assests/Images/Common/polygon.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import LocalizationContext from "../../../Utils/LocalizationContext";
import { useContext } from "react";
import "./index.css";

const CategoryMenu = ({ isCloseMenu }) => {
  const industriesAndCategories = useSelector(
    (state) => state.CommonReducer.industriesAndCategories
  );

  const [isOpen, setIsOpen] = useState(false);
  const { t } = useContext(LocalizationContext);
  const menuRef = useRef(null);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const isCloseValue = () => {
    if (isCloseMenu) {
      isCloseMenu();
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    }
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={menuRef}
      className={`category-mega-menu ${isOpen ? "list-open" : ""}`}
    >
      <div onClick={toggleMenu} className="menu-button">
        {t("zaraye.marketplace.categories.text")}
        {isOpen && (
          <span className="polygon-icon">
            {" "}
            <img
              src={menuPolygonIcon}
              alt={menuPolygonIcon}
              loading="lazy"
            />{" "}
          </span>
        )}
      </div>
      <div className="menu-content">
        <div className="menu-category">
          {industriesAndCategories.map((item, index) => (
            <ul key={`Industry-item-${index}`} className="category-menu-column">
              <li onClick={isCloseValue}>
                <div className="ct-list">
                  <h2 className="mb-4">
                    {" "}
                    <Link to={item.SeName} onClick={closeMenu}>
                      {item.Name}
                    </Link>
                  </h2>
                  {item.Categories.map((item2, index2) => (
                    <Link
                      onClick={closeMenu}
                      key={`Category-item-${index2}`}
                      to={item2.SeName}
                      className="text-decoration-none"
                    >
                      <div className="ct-list-items d-flex align-items-center">
                        <div className="ct-list-img">
                          <img
                            src={item2.Picture}
                            alt={item2.Picture}
                            loading="lazy"
                          />
                        </div>
                        <div className="ct-list-title ms-3">
                          <p className="mb-0"> {item2.Name}</p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </li>
            </ul>
          ))}
        </div>

        {/* Add more columns */}
      </div>
    </div>
  );
};

export default CategoryMenu;

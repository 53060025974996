import React, { useContext, useState } from "react";
import ZarayeBackDrop from "../../../Assests/Images/Zaraye/header-back-drop-logo.png";
import BrandSlider from "../../../Components/Zaraye/BrandSlider";
import GetZarayeApp from "../../../Components/Common/getZarayeApp";
import OurCustomersSlider from "../../../Components/Common/OurCustomersSlider";
import CustomModal from "../../../Components/Zaraye/CustomModal";
import OneStopSolution from "../../../Components/Zaraye/OneStopSolution";
import HeadingDescription from "../../../Components/Common/SectionHeadingDescription";
import redefiningImg from "../../../Assests/Images/MarketPlace/redefining.png";
import company from "../../../Assests/Images/MarketPlace/mp-company.png";
import { Container, Row } from "react-bootstrap";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import CustomButton from "../../../Components/Common/CustomBtn";
import Story from "../../../Assests/Images/Zaraye/story.svg";
import TGA from "../../../Assests/Images/MarketPlace/tga.png";
import Bookme from "../../../Assests/Images/MarketPlace/bookme.png";
import Finja from "../../../Assests/Images/MarketPlace/finja.png";
import OkayKer from "../../../Assests/Images/MarketPlace/okay-ker.png";
import Abhi from "../../../Assests/Images/MarketPlace/abhi.png";
import BusCaro from "../../../Assests/Images/MarketPlace/bus-caro.png";
import AllInOne from "../../../Assests/Images/MarketPlace/all-for-one.svg";
import Adventure from "../../../Assests/Images/MarketPlace/adventure.svg";
import FindAWay from "../../../Assests/Images/MarketPlace/find-a-way.svg";
import Conversation from "../../../Assests/Images/MarketPlace/conversation-icon.svg";
import Learning from "../../../Assests/Images/MarketPlace/learning.svg";
import TakeCharge from "../../../Assests/Images/MarketPlace/take-charge.svg";
import TigerGlobal from "../../../Assests/Images/MarketPlace/tiger-global.svg";
import ZainVC from "../../../Assests/Images/MarketPlace/zain-vc.svg";
import Ventures from "../../../Assests/Images/MarketPlace/92-ventures.svg";
import Fundamentals from "../../../Assests/Images/MarketPlace/fundamentals.svg";
import AsiaVc from "../../../Assests/Images/MarketPlace/asia-vc.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { ReactComponent as PreviousDark } from "../../../Assests/Images/Zaraye/previous.svg";
import { ReactComponent as NextDark } from "../../../Assests/Images/Zaraye/next.svg";
import CoFounderMessage from "../../../Components/Zaraye/CoFounderMessage";
import LocalizationContext from "../../../Utils/LocalizationContext";
import "./index.css";

const CompanyPage = () => {
  const allBrands = useSelector((state) => state.CommonReducer.allBrands);
  const [showModal, setShowModal] = useState(false);
  const { t } = useContext(LocalizationContext)
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const settings = {
    centerMode: false,
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 5,
          autoplay: true,
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
          centerMode: false,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
          centerMode: false,
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        }
      },
    ]
  };

  const valueSettings = {
    centerMode: true,
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1799,
        settings: {
          slidesToShow: 6,
          centerMode: false,

        }
      },
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          centerMode: false,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 475,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        }
      }
    ]
  };

  const investorSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 5,
          autoplay: true,
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 2,
        }
      },
    ]
  };

  const brandSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    swipeToSlide: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    speed: 2000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          arrows: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
          arrows: true,
          autoplay: false,
          nextArrow: <NextDark />,
          prevArrow: <PreviousDark />,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 3,
          arrows: true,
          autoplay: false,
          nextArrow: <NextDark />,
          prevArrow: <PreviousDark />,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 2,
          autoplay: false,
          arrows: true,
          nextArrow: <NextDark />,
          prevArrow: <PreviousDark />,
        },
      },
    ],
  };

  const brands = [
    {
      id: 1,
      image: AllInOne,
      description: "All for one, one for all",
    },
    {
      id: 2,
      image: Adventure,
      description: "Embrace the adventure",
    },
    {
      id: 3,
      image: FindAWay,
      description: "We either find a way or make one",
    },
    {
      id: 4,
      image: Conversation,
      description: "Start With Why Get Wow'd",
    },
    {
      id: 5,
      image: Learning,
      description: "We Make Our Customers the star of our story",
    },
    {
      id: 6,
      image: Story,
      description: "Take charge, make things happen",
    },
    {
      id: 7,
      image: TakeCharge,
      description: "We either find a way or make one",
    },
    {
      id: 8,
      image: Story,
      description: "Embrace the adventure",
    },
  ];

  const networkBrands = [
    {
      id: 1,
      image: TGA,
    },
    {
      id: 2,
      image: Bookme,
    },
    {
      id: 3,
      image: Finja,
    },
    {
      id: 4,
      image: OkayKer,
    },
    {
      id: 5,
      image: Abhi,
    },
    {
      id: 6,
      image: BusCaro,
    },
  ];

  const investers = [
    {
      id: 1,
      image: TigerGlobal,
    },
    {
      id: 2,
      image: ZainVC,
    },
    {
      id: 3,
      image: Ventures,
    },
    {
      id: 4,
      image: Fundamentals,
    },
    {
      id: 5,
      image: AsiaVc,
    },
  ];

  return (
    <div className="company-pg">
      <CustomModal show={showModal} handleClose={handleCloseModal} />
      <div className="header-back-drop-logo">
        <img src={ZarayeBackDrop} alt={ZarayeBackDrop} loading="lazy" />
      </div>

      <div className="container hero-section">
        <div className="breadcrumb">
          <span className="pe-1 unactive">
            <Link to={"/"}>{t('zaraye.marketplace.home.breadcrumbs')}</Link>
          </span>
          <span className="pe-1 greater">
            <img src={ArrowLeft} width={14} alt="greater than" loading="lazy" />
          </span>
          <span className="activelink">{t('zaraye.marketplace.company.breadcrumbs')}</span>
        </div>
        <div className="row align-items-center justify-content-between zaraye-hero-section">
          <div className="col-md-7 col-xl-7 col-xxl-6 home-title">
            <h1 className="welcome">{t("zaraye.marketplace.welcometozaraye.text")}</h1>
            <div className="col-lg-10">
              <h1 className="main-heading mb-4">{t('zaraye.marketplace.sourcingrediscovered.text')}</h1>
            </div>
            <p>{t('zaraye.marketplace.sourcingrediscovered.description')}</p>
            <div className="explore-box">
              <CustomButton
                className="primary-btn"
                text={t('zaraye.marketplace.discoverzaraye.text')}
              />
              <p className="get-customize mb-0">
                <Link to="/">{t('zaraye.marketplace.sourcingrediscovered.link')}</Link>
              </p>
            </div>
          </div>
          <div className="col-md-5 text-end d-none d-md-block">
            <img src={company} alt={company} loading="lazy" className="w-100" />
          </div>
        </div>
      </div>
      <Container>
        <div className="row align-items-md-start align-items-lg-center justify-content-between">
          <div className="col-md-5">
            <img
              src={redefiningImg}
              alt={redefiningImg}
              className="w-100 company-img"
              loading="lazy"
            />
          </div>
          <div className={`col-md-6 text-center ${document.documentElement.dir === "rtl" ? "text-md-end" : "text-md-start"}`}>
            <div className="col-lg-8 home-title">
              <h1 className="main-heading">{t('zaraye.marketplace.zarayeredefiningsourcingexcellence.text')}</h1>
            </div>
            <p>{t('zaraye.marketplace.zarayeredefiningsourcingexcellence.description')}</p>
            <p>{t('zaraye.marketplace.zarayeredefiningsourcingexcellence.description2')}</p>
          </div>
        </div>
      </Container>

      <section className="our-colab-network">
        <Container>
          <Row className="text-center collaborative">
            <div className="col-sm-10 col-md-8 col-lg-8 col-xl-7 home-title mx-auto text-center text-md-start">
              <h1 className="main-heading text-center">{t('zaraye.marketplace.ourcollaborativenetwork.text')}</h1>
            </div>
            <div className="col-sm-9 col-md-10 col-lg-10 col-xl-8 mx-auto">
              <p>{t('zaraye.marketplace.ourcollaborativenetwork.description')}</p>
            </div>
          </Row>
        </Container>
        <div className="container-fluid zaraye-core-values">
          <Container>
            <Row>
              <div className="col-md-12">
                <div className="about-brand-slider-section text-center network-brands">
                  <Slider {...settings}>
                    {networkBrands?.map((item, index) => (
                      <Link key={`brand-item-${index}`} to={`/${item?.SeName}`}>
                        <div className={`brand-item`}>
                          <img src={item?.image} alt={item?.description} loading="lazy" />
                        </div>
                        <p>{item?.description}</p>
                      </Link>
                    ))}
                  </Slider>
                </div>
              </div>
              <div className="col-md-8 mx-auto text-center">
                <HeadingDescription
                  headingText={t("zaraye.marketplace.unveilingzarayecorevalues.text")}
                  descriptionOne={t("zaraye.marketplace.unveilingzarayecorevalues.description")}
                />
              </div>
            </Row>
          </Container>
          <Row className="cmx-auto">
            <div className="col-md-12 core-values-slider">
              <div className="position-relative">
                <div className="brand-slider">
                  <Slider {...valueSettings}>
                    {brands?.map((item, index) => (
                      <div key={`brand-item-${index}`}>
                        <div className="brand-item">
                          <div className="brand-item-img d-flex align-items-center justify-content-center">
                            <img src={item?.image} alt={item?.description} loading="lazy" />
                          </div>
                          <span>{item?.description}</span>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </section>

      <OneStopSolution />
      <section className="our-visionaries-section">
        <CoFounderMessage />
      </section>

      <section className="valued-investor-section">
        <Container>
          <Row className="text-center collaborative">
            <div className="col-sm-10 col-md-8 col-lg-8 col-xl-6 home-title mx-auto">
              <h1 className="main-heading text-center">{t('zaraye.marketplace.ourvaluedinvestors.text')}</h1>
            </div>
            <div className="col-sm-9 col-md-10 col-lg-10 col-xl-7 mx-auto">
              <p>{t('zaraye.marketplace.ourvaluedinvestors.description')}</p>
            </div>
          </Row>
        </Container>
        <div className="container-fluid zaraye-core-values company-manufactures">
          <Container>
            <Row>
              <div className="col-md-12">
                <div className="about-brand-slider-section text-center investors-img">
                  <Slider {...investorSettings}>
                    {investers?.map((item, index) => (
                      <Link
                        key={`investor-item-${index}`}
                        to={`/${item?.SeName}`}
                      >
                        <div className={`brand-item`}>
                          <img src={item?.image} alt={item?.description} loading="lazy" />
                        </div>
                      </Link>
                    ))}
                  </Slider>
                </div>
              </div>
              <div className="col-md-8 mx-auto text-center">
                <HeadingDescription
                  headingText={t("zaraye.marketplace.trustedmanufacturersandbrands.text")}
                  descriptionOne={t("zaraye.marketplace.trustedmanufacturersandbrands.description")}
                />
              </div>
            </Row>
          </Container>
          <Row>
            <div className="col-md-12 company-brands">
              <BrandSlider
                data={allBrands}
                settings={brandSettings}
                showDescriptions={false}
              />
            </div>
          </Row>
        </div>
      </section>

      <section className="mp-home testinomials">
        <OurCustomersSlider />
      </section>
      <GetZarayeApp />
    </div>
  );
};

export default CompanyPage;

import { useContext, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import doubleCheck from "../../../Assests/Images/Common/double-check.svg";
import HeroMainTitle from "../../../Components/Common/HeroMainTitle";
import SecurityService from "../../../Services/Security/SecurityService";
import Input from "../../../Components/Common/input";
import { useSelector } from "react-redux";
import SelectDropdown from "../../../Components/Common/selectDropdown";
import Button from "../../../Components/Common/CustomBtn";
import { toast } from "react-toastify";
import LocalizationContext from "../../../Utils/LocalizationContext";
import PhoneNumberInput from "../../../Components/Common/countryInput";
import "./index.css";

const BecomeASupplier = () => {
  const allCountries = useSelector((state) => state.CommonReducer.countries);
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [selectCountry, setSelectedCountry] = useState({});
  const [city, setCity] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [phoneNumberValidity, setPhoneNumberValidity] = useState(false);
  const { t } = useContext(LocalizationContext);
  const countryPlaceholder = t("zaraye.marketplace.country.placeholder");

  const quoteFormDropdownStyles = {
    menu: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: "1px solid rgb(15 138 140 / 50%)",
      marginLeft: "-10px",
      overflow: "hidden",
    }),
    menuList: (provided, state) => ({
      ...provided,
      maxHeight: "200px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgba(0, 0, 0, 0.5)",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 8px",
    }),
    container: (provided, state) => ({
      ...provided,
      border: "1px solid rgb(15 138 140 / 50%)",
      borderRadius: "40px",
      padding: "1.2px 10px",
      background: "#ffffff42",
      marginTop: "11px",
      fontSize: "13px",
      fontFamily: "Regular",
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      background: "none",
      minHeight: "31px",
      padding: "0",
      border: "0",
      svg: {
        height: "17px",
        width: "17px",
      },
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#808080",
      border: "0",
      padding: "0",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "teal" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: "teal",
        color: "white",
      },
    }),
  };

  const handleSelectCountry = (selectCountry) => {
    setSelectedCountry(selectCountry);
  };

  const handleOnClickSubmitRequest = async () => {
    if (name === "") {
      toast.error(t("zaraye.marketplace.pleaseenteryourname.error"));
    } else if (companyName === "") {
      toast.error(t("zaraye.marketplace.pleaseentercompanyname.error"));
    } else if (phoneNumber === "") {
      toast.error(t("zaraye.marketplace.pleaseenteryourphonenumber.error"));
    } else if (!phoneNumberValidity) {
      toast.error(t("zaraye.marketplace.pleaseenterphonenumber.error"));
    } else if (email === "") {
      toast.error(t("zaraye.marketplace.pleaseenteryouremail.error"));
    } else if (!selectCountry?.Value) {
      toast.error(t("zaraye.marketplace.pleaseselectcountry.error"));
    } else if (city === "") {
      toast.error(t("zaraye.marketplace.pleaseentercityname.error"));
    } else if (productDescription === "") {
      toast.error(t("zaraye.marketplace.pleasetypeproductdescription.error"));
    } else {
      const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (reg.test(email) === false) {
        toast.error(t("zaraye.marketplace.pleaseentervalidemail.error"));
      } else {
        var payload = {
          name: name,
          companyName: companyName,
          phoneNumber: phoneNumber,
          email: email,
          countryId: selectCountry?.Value,
          cityId: 2147483647,
          productDescription: productDescription,
        };
        var result = await SecurityService?.SupplierRegister(payload);
        if (result?.success) {
          setSelectedCountry("");
          setName("");
          setCompanyName("");
          setProductDescription("");
          setCity("");
          setEmail("");
          setPhoneNumber("");
          toast.success(
            t("zaraye.marketplace.leadsuccessfullygenerated.success")
          );
        }
      }
    }
  };

  return (
    <Container className="become-supplier-pg">
      <div className="breadcrumb">
        <span className="pe-1 unactive">
          <Link to={"/"}>{t("zaraye.marketplace.home.breadcrumbs")}</Link>
        </span>
        <span className="pe-1 greater">
          <img src={ArrowLeft} width={14} alt="greater than" />
        </span>
        <span className="activelink">
          {t("zaraye.marketplace.becomeasupplier.text")}
        </span>
      </div>
      <Row className="justify-content-between align-items-center">
        <div className="col-lg-7 col-xl-6">
          <HeroMainTitle
            topTagLine={t("zaraye.marketplace.becomeasupplier.text")}
            title={t("zaraye.marketplace.elevateyourbusiness.text")}
            description={t(
              "zaraye.marketplace.elevateyourbusiness.description"
            )}
          />
          <ul className="bcm-sup-list">
            <li>
              <img src={doubleCheck} className="me-2" loading="lazy" alt="doubleCheck" />
              {t("zaraye.marketplace.effortlesssetup.text")}
            </li>
            <li>
              <img src={doubleCheck} className="me-2" loading="lazy" alt="doubleCheck" />
              {t("zaraye.marketplace.streamlinedprocess.text")}
            </li>
            <li>
              <img src={doubleCheck} className="me-2" loading="lazy" alt="doubleCheck" />
              {t("zaraye.marketplace.qualityassurance.text")}
            </li>
            <li>
              <img src={doubleCheck} className="me-2" loading="lazy" alt="doubleCheck" />
              {t("zaraye.marketplace.dedicatedsupport.text")}
            </li>
            <li>
              <img src={doubleCheck} className="me-2" loading="lazy" alt="doubleCheck" />
              {t("zaraye.marketplace.globalreach.text")}
            </li>
            <li>
              <img src={doubleCheck} className="me-2" loading="lazy" alt="doubleCheck" />
              {t("zaraye.marketplace.businessgrowth.text")}
            </li>
            <li>
              <img src={doubleCheck} className="me-2" loading="lazy" alt="doubleCheck" />
              {t("zaraye.marketplace.increasedvisibility.text")}
            </li>
            <li>
              <img src={doubleCheck} className="me-2" loading="lazy" alt="doubleCheck" />
              {t("zaraye.marketplace.marketexpansion.text")}
            </li>
          </ul>
        </div>
        <div className="col-lg-5 col-xl-5 mb-5 mb-md-0">
          <div className="main-form">
            <h1 className="get-quote">
              {t("zaraye.marketplace.registerassupplier.text")}
            </h1>
            <p className="mb-0">
              {t("zaraye.marketplace.registerassupplier.description")}
            </p>
            <div className="d-block">
              <Input
                className={"form-control"}
                placeholder={t("zaraye.marketplace.enteryourname.placeholder")}
                type="text"
                value={name}
                maxLength={50}
                onChange={(e) => {
                  if (!e.target.value || /^[a-zA-Z\s]+$/.test(e.target.value)) {
                    setName(e.target.value);
                  }
                }}
                name={"name"}
              />
            </div>
            <div className="d-block">
              <Input
                className={"form-control"}
                placeholder={t(
                  "zaraye.marketplace.entercompanyname.placeholder"
                )}
                type="text"
                maxLength={100}
                name={"companyName"}
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                }}
              />
            </div>
            <div className="d-block">
              <PhoneNumberInput
                phoneNumber={phoneNumber}
                onChangePhonenumber={(value) => {
                  setPhoneNumber(value);
                }}
                onPhoneNumberValidityChange={(validity) => {
                  setPhoneNumberValidity(validity);
                }}
              />
            </div>
            <div className="d-block">
              <Input
                className={"form-control"}
                placeholder={t("zaraye.marketplace.emailaddress.placeholder")}
                type="email"
                value={email}
                maxLength={50}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                name={"Email Address"}
              />
            </div>
            <div className="d-flex form-6-colum flex-wrap justify-content-between">
              <div className="form-group">
                <SelectDropdown
                  data={allCountries}
                  optionValueProp="Value"
                  optionLabelProp="Text"
                  value={selectCountry}
                  onChange={handleSelectCountry}
                  placeholder={countryPlaceholder}
                  styles={quoteFormDropdownStyles}
                />
              </div>
              <div className="form-group">
                <Input
                  className={"form-control"}
                  placeholder={t("zaraye.marketplace.cityname.placeholder")}
                  type="text"
                  value={city}
                  onChange={(e) => {
                    if (!e.target.value || /^[a-zA-Z\s]+$/.test(e.target.value)) {
                      setCity(e.target.value);
                    }
                  }}
                  name={"city"}
                />
              </div>
            </div>
            <div className="d-block">
              <Input
                className={"form-control"}
                placeholder={t(
                  "zaraye.marketplace.productdescription.placeholder"
                )}
                type="text"
                value={productDescription}
                onChange={(e) => {
                  setProductDescription(e.target.value);
                }}
                name={"productDescription"}
              />
            </div>
            <div className="d-block">
              <Button
                className={"primary-btn w-100 text-center ripple"}
                text={t("zaraye.marketplace.registerassupplier.btntext")}
                onClick={handleOnClickSubmitRequest}
              />
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default BecomeASupplier;

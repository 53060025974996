import React, { useState, useEffect, useRef } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";
import './index.css';

const DropdownMenu = ({ menuTitle, options, className, onChangeDropdown }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const storedLanguage = localStorage.getItem('selectedLanguage');
    const initialSelectedOption = options.find(item => item.UniqueSeoCode === (storedLanguage || 'en'));
    const [selectedOption, setSelectedOption] = useState(initialSelectedOption);
    const dropdownRef = useRef(null);

    const handleOnChangeDropDown = (id) => {
        const selected = options.find(item => item.Id === id);
        setSelectedOption(selected);
        localStorage.setItem('selectedLanguage', selected.UniqueSeoCode);
        if (onChangeDropdown) {
            onChangeDropdown(id);
        }
        setShowDropdown(false);
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div ref={dropdownRef}>
            <Dropdown show={showDropdown} onToggle={(isOpen) => setShowDropdown(!isOpen)} className='custom-dropdown'>
                <Dropdown.Toggle as="div" className={className} onClick={() => setShowDropdown(!showDropdown)}>
                    {selectedOption && (
                        <div>
                            <img
                                src={`https://revamp.zortal.co/images/flags/${selectedOption.FlagImageFileName}`}
                                alt={selectedOption.UniqueSeoCode}
                                className='lang-flag'
                            />
                            {selectedOption.UniqueSeoCode}
                        </div>
                    )}
                    {!selectedOption && menuTitle}
                </Dropdown.Toggle>
                <Dropdown.Menu className='lang-options'>
                    {options.map((item, index) => (
                        <div
                            onClick={() => handleOnChangeDropDown(item?.Id)}
                            key={`language-dropdown-${index}`}
                            className='dropdown-item'
                        >
                            <img
                                src={`https://revamp.zortal.co/images/flags/${item.FlagImageFileName}`}
                                alt={item.UniqueSeoCode}
                                className='lang-flag'
                            />
                            {item.UniqueSeoCode}
                        </div>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default DropdownMenu;

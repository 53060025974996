import React, { useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import LandingPagesMainTitle from "../../../Components/Zaraye/LandingPagesTitle";
import Input from "../../../Components/Common/input";
import SelectDropdown from "../../../Components/Common/selectDropdown";
import Button from "../../../Components/Common/CustomBtn";
import HeadingDescription from "../../../Components/Common/SectionHeadingDescription";
import HandShake from "../../../Assests/Images/MarketPlace/hand-shake.png";
import { useSelector } from "react-redux";
import LocalizationContext from "../../../Utils/LocalizationContext";
import PhoneNumberInput from "../../../Components/Common/countryInput";
import CommonService from "../../../Services/Common/CommonService";
import { toast } from "react-toastify";
import "./style.css";

const ContactUsPage = ({ item }) => {
  const allCountries = useSelector((state) => state.CommonReducer.countries);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [selectCountry, setSelectedCountry] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumberValidity, setPhoneNumberValidity] = useState(false);
  const { t } = useContext(LocalizationContext);
  const isRtl = document.documentElement.getAttribute("dir") === "rtl";

  const quoteFormDropdownStyles = {
    menu: (provided, state) => ({
      ...provided,
      borderRadius: "8px", // Add border-radius
      border: "1px solid rgb(15 138 140 / 50%)",
      marginLeft: "-10px",
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "rgba(0, 0, 0, 0.5)",
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),

    valueContainer: (provided) => ({
      ...provided,
      padding: "4px 8px",
    }),

    container: (provided, state) => ({
      ...provided,
      border: "1px solid rgb(15 138 140 / 50%)",
      borderRadius: "40px",
      padding: "0 10px",
      background: "#ffffff42",
      marginTop: "10px",
      fontSize: "13px",
      fontFamily: isRtl ? "Gulzar" : "Regular",
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      background: "none",
      minHeight: "32px",
      border: "0",
      svg: {
        height: "17px",
        width: "17px",
      },
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#808080",
      border: "0",
      padding: "0",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "teal" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: "teal",
        color: "white",
        // border: "2px solid",
      },
    }),
  };
  const countryPlaceholder = t("zaraye.marketplace.country.placeholder");

  const handleSelectCountry = (selectCountry) => {
    setSelectedCountry(selectCountry);
  };

  const handleOnClickSubmitRequest = async () => {
    if (name === "") {
      toast.error(t("zaraye.marketplace.pleaseenteryourname.error"));
    } else if (phoneNumber === "") {
      toast.error(t("zaraye.marketplace.pleaseenteryourphonenumber.error"));
    } else if (!phoneNumberValidity) {
      toast.error(t("zaraye.marketplace.pleaseenterphonenumber.error"));
    } else if (email === "") {
      toast.error(t("zaraye.marketplace.pleaseenteryouremail.error"));
    } else if (!selectCountry?.Value) {
      toast.error(t("zaraye.marketplace.pleaseselectcountry.error"));
    } else if (companyName === "") {
      toast.error(t("enter company name"));
    } else {
      const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (reg.test(email) === false) {
        toast.error(t("zaraye.marketplace.pleaseentervalidemail.error"));
      } else {
        var payload = {
          email: email,
          subject: "subject",
          enquiry: "enquiry",
          fullName: name,
          contactNo: phoneNumber,
          country: selectCountry?.Value,
          companyDomain: companyName,
          result: "",
        };
        var result = await CommonService?.ContactUs(payload);
        if (result?.success) {
          setSelectedCountry("");
          setName("");
          setEmail("");
          setPhoneNumber("");
          setCompanyName("");
          toast.success("Lead Successfully Generated");
        }
      }
    }
  };

  const locationText = (t('zaraye.marketplace.zarayelocation.text'));

  const handleOnClickEmailClick = () => {
    window.location.href = `mailto:${"Info@zaraye.co"}`;
  };

  const handleLocationClick = (location) => {
    const formattedLocation = location.replace(/ /g, "+");
    const mapUrl = `https://www.google.com/maps/search/?api=1&query=${formattedLocation}`;
    window.open(mapUrl, "_blank");
  };

  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <meta
          data-react-helmet="true"
          name="description"
          content={item?.MetaDescription ? item?.MetaDescription : ""}
        />
        <meta
          data-react-helmet="true"
          name="keywords"
          content={item?.MetaKeywords ? item?.MetaKeywords : ""}
        />
        <title>
          {item?.MetaTitle
            ? item?.MetaTitle
            : t("zaraye.marketplace.zarayeb2brawmaterialplatform.metatitle")}
        </title>
        {item?.Slug && (
          <link
            rel="canonical"
            href={`http://app-react.zaraye.co/${item?.Slug}`}
          />
        )}
      </Helmet>
      <section className="contact-page">
        <Container>
          <div className="breadcrumb">
            <span className="pe-1 unactive">
              <Link to={"/"}>{t("zaraye.marketplace.home.breadcrumbs")}</Link>
            </span>
            <span className="pe-1 greater">
              <img
                src={ArrowLeft}
                width={14}
                alt="greater than"
                loading="lazy"
              />
            </span>
            <span className="activelink">
              {t("zaraye.marketplace.contactus.breadcrumbs")}
            </span>
          </div>
          <Row>
            <LandingPagesMainTitle
              headingText={t("zaraye.marketplace.contactus.main.title")}
              descriptionOne={t(
                "zaraye.marketplace.contactus.main.description"
              )}
            />
          </Row>
          <Row className="justify-content-between" id="contact-form">
            <div className="col-md-4 pt-5 pb-4 order-last order-md-first">
              <h2 className="section-heading pb-3">
                {t("zaraye.marketplace.zarayecontactinformation.text")}
              </h2>
              <div className="contactus-left">
                <h2 className="section-inner-heading mb-1">
                  {t("zaraye.marketplace.contactnumber.contact")}
                </h2>
                <span>+92 316 222 9990</span>
              </div>
              <div className="contactus-left">
                <h2 className="section-inner-heading mb-1">
                  {t("zaraye.marketplace.zarayeemailaddress.email")}
                </h2>
                <span onClick={handleOnClickEmailClick}>Info@zaraye.co</span>
              </div>
              <div className="contactus-left">
                <h2 className="section-inner-heading mb-1">
                  {t("zaraye.marketplace.zarayeheadoffice.text")}
                </h2>
                <span className="location" onClick={() => handleLocationClick(locationText)}>
                  {locationText}
                </span>
              </div>
            </div>
            <div className="col-md-8 col-lg-7 col-xl-6 contactus">
              <div className="main-form">
                <h1 className="get-quote">
                  {t("zaraye.marketplace.letsconnect.text")}
                </h1>
                <p className="mb-0">
                  {t("zaraye.marketplace.letsconnect.description")}
                </p>
                <div className="row pt-4">
                  <div className="col-md-6 pb-2">
                    <div className="form-group">
                      <Input
                        className={"form-control"}
                        placeholder={t(
                          "zaraye.marketplace.enteryourname.placeholder"
                        )}
                        type="text"
                        value={name}
                        maxLength={50}
                        onChange={(e) => {
                          if (
                            !e.target.value ||
                            /^[a-zA-Z\s]+$/.test(e.target.value)
                          ) {
                            setName(e.target.value);
                          }
                        }}
                        name={"name"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 pb-2">
                    <div className="form-group">
                      <PhoneNumberInput
                        phoneNumber={phoneNumber}
                        onChangePhonenumber={(value) => {
                          setPhoneNumber(value);
                        }}
                        onPhoneNumberValidityChange={(validity) => {
                          setPhoneNumberValidity(validity);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 pb-2">
                    <div className="form-group">
                      {/* <Input
                        className={"form-control"}
                        placeholder={t(
                          "zaraye.marketplace.emailaddress.placeholder"
                        )}
                        type="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        name={"Email Address"}
                      /> */}
                      <Input
                        className={"form-control"}
                        placeholder={t(
                          "zaraye.marketplace.emailaddress.placeholder"
                        )}
                        type="email"
                        value={email}
                        maxLength={50}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        name={"Email Address"}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 pb-2">
                    <div className="form-group countries">
                      <SelectDropdown
                        data={allCountries}
                        optionValueProp="Value"
                        optionLabelProp="Text"
                        value={selectCountry}
                        onChange={handleSelectCountry}
                        placeholder={countryPlaceholder}
                        styles={quoteFormDropdownStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 pb-2">
                    <div className="form-group">
                      <Input
                        className={"form-control"}
                        placeholder={t(
                          "zaraye.marketplace.entercompanyname.placeholder"
                        )}
                        type="text"
                        name={"companyName"}
                        value={companyName}
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 pb-2">
                    <div className="form-group">
                      <textarea
                        placeholder={t(
                          "zaraye.marketplace.writemessage.placeholder"
                        )}
                        name="message"
                        rows="4"
                        className="form-control w-100"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <Button
                      className={"primary-btn w-100 text-center ripple"}
                      text={t("zaraye.marketplace.submit.btntext")}
                      onClick={handleOnClickSubmitRequest}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <Row className="contactus-footer align-items-center justify-content-between">
            <div className="col-md-6 solution-pg-title">
              <HeadingDescription
                headingText={t(
                  "zaraye.marketplace.ourcustomersareourpriority!.text"
                )}
                descriptionOne={t(
                  "zaraye.marketplace.ourcustomersareourpriority!.description"
                )}
              />
              <Link
                to="/contactus"
                className={"primary-btn mt-3 text-decoration-none"}
              >
                {t("zaraye.marketplace.gethelpnow.btntext")}
              </Link>
              {/* <Button text={t('zaraye.marketplace.gethelpnow.btntext')} onClick={() => navigate('#')}  /> */}
            </div>
            <div className="col-md-5 justify-content-end contactus-priority order-first order-md-last">
              <img
                src={HandShake}
                alt={HandShake}
                className="img-fluid"
                loading="lazy"
              />
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ContactUsPage;

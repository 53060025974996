import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Provider } from 'react-redux';
import { store } from './Store/Store';
import LoadingPage from './Pages/Common/Loading';
import 'intl-tel-input/build/css/intlTelInput.css';

const App = () => {
  return (
    <Provider store={store}>
      <LoadingPage />
    </Provider>
  );
}

export default App;

const Input = ({
  className,
  onBlur,
  type,
  placeholder,
  value,
  name,
  onChange,
  disabled,
  maxLength,
  id,
}) => {
  return (
    <input
      className={className}
      onBlur={onBlur}
      type={type}
      id={id}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      name={name}
      onChange={onChange}
      maxLength={maxLength}
    />
  );
};

export default Input;

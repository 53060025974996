import React, { useContext } from "react";
import logo from "../../../Assests/Images/Global/footer-logo.png";
import playStoreLogo from "../../../Assests/Images/Common/googleplay.png";
import iosLogo from "../../../Assests/Images/Common/appstore.png";
import { Link } from "react-router-dom";
import "./index.css";
import LocalizationContext from "../../../Utils/LocalizationContext";

const Footer = () => {
  const {t} = useContext(LocalizationContext)
  return (
    <div className="footer-section global-footer">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-5 col-lg-4 col-xl-5 order-2 order-md-1">
            <img src={logo} alt={logo} className="global-footer-logo" loading="lazy" />
            <p className="mt-3 mt-md-5">{t('zaraye.marketplace.copyright.text')}</p>
          </div>
          <div
          className={`col-10 col-md-6 col-lg-6 col-xl-4 d-flex align-items-center  flex-wrap global-footer-right order-1 order-md-2 ${
            document.documentElement.dir === "rtl" ? "justify-content-start" : "justify-content-end"
          }`} 
          >
            <div className="zaraye-market">
              <h4>
                <Link to="/"> {t('zaraye.marketplace.zaraye.text')}</Link>
              </h4>
              <h4>
                <Link to="/solution"> {t('zaraye.marketplace.solutions.text')}</Link>
              </h4>
              <h4>
                <Link to="/company"> {t('zaraye.marketplace.company.text')}</Link>
              </h4>
            </div>
            <div className="footer-logos d-none d-md-block">
              <Link
                to="https://play.google.com/store/apps/details?id=com.zaraye.app"
                target="_blank"
              >
                <img 
                  src={playStoreLogo} 
                  alt={playStoreLogo} 
                  className={`col-md-8 col-lg-6 ${
                    document.documentElement.dir === "rtl" ? "ms-4" : "me-4"
                  }`} 
                  loading="lazy" 
                />
              </Link>
              <Link
                to="https://apps.apple.com/app/id1608301344"
                target="_blank"
              >
                <img src={iosLogo} alt={iosLogo} loading="lazy" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Slider from "react-slick";
import { useSelector } from 'react-redux';
import { ReactComponent as NextWhite } from "../../../Assests/Images/MarketPlace/next-white.svg";
import './style.css'
import LocalizationContext from '../../../Utils/LocalizationContext';

const HeroIndustrySlider = () => {
    const topWebSlider = useSelector((state) => state.CommonReducer.allTopWebSliders);
    const {t} = useContext(LocalizationContext)
    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        nextArrow: <NextWhite />,
        fade: true,
        speed: 800,
        className: "slick-slider-fade",
        cssEase: "linear",
    };

    return (
        <div className="hero-industies-slider-row">
            <Slider {...settings} className='h-100'>
                {topWebSlider?.map((item, index) => {
                    return (
                        <div key={`hero-web-slider-${index}`} className="hero-industies-slider position-relative">
                            <div className="his-img">
                                <img src={item?.Picture} alt={item?.Title} loading="lazy" width={'100%'} height={'100%'} />
                            </div>
                            <div className="his-content">
                                <h1 className='text-white'>{item?.Title}</h1>
                                <p className='text-white' dangerouslySetInnerHTML={{__html:item?.Description}}></p>
                                <Link to={`${item?.Link}`}>
                                    {t('zaraye.marketplace.exploreindustry.linktext')}
                                </Link>
                            </div>
                        </div>
                    )
                })}
            </Slider>
        </div>
    )
}

export default HeroIndustrySlider

import React from 'react'
import CustomDropdown from '../CustomDropdown'
import './index.css'

const FilterDropdown = ({title, pageSize = []}) => {
    
    return (
        <div className='filter'>
            <p className='mb-0'>{title}:</p>
            <CustomDropdown  options={pageSize} className={'custom-dropdown-icon'}/>
        </div>
    )
}

export default FilterDropdown

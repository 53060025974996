import { useContext, useEffect, useState } from "react";
import CommonService from "../../../Services/Common/CommonService";
import { Container, Row } from "react-bootstrap";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import LocalizationContext from "../../../Utils/LocalizationContext";

const BlogDetailPage = ({ item }) => {
  const { t } = useContext(LocalizationContext);
  const languageId = useSelector((state) => state.CommonReducer?.languageId);
  const [blogData, setBlogData] = useState({});

  const getBlogDetail = async (id) => {
    var response = await CommonService.GetBlogById(id);
    if (response?.success) {
      setBlogData(response?.data);
    }
  };

  useEffect(() => {
    getBlogDetail(item?.Id);
  }, [item?.Id, languageId]);

  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <meta
          data-react-helmet="true"
          name="description"
          content={item?.MetaDescription ? item?.MetaDescription : ""}
        />
        <meta
          data-react-helmet="true"
          name="keywords"
          content={item?.MetaKeywords ? item?.MetaKeywords : ""}
        />
        <title>
          {item?.MetaTitle
            ? item?.MetaTitle
            : t("zaraye.marketplace.zarayeb2brawmaterialplatform.metatitle")}
        </title>
        {item?.Slug && (
          <link
            rel="canonical"
            href={`https://www.react-app.zaraye.co/${item?.Slug}`}
          />
        )}
      </Helmet>
      <Container className="topic-detail-pg">
        <div className="breadcrumb">
          <span className="pe-1 unactive">
            <Link to={"/"}>{t("zaraye.marketplace.home.breadcrumbs")}</Link>
          </span>
          <span className="pe-1 greater">
            <img src={ArrowLeft} width={14} alt="greater than" loading="lazy" />
          </span>
          <span className="pe-1 unactive">
            <Link to={"/blogs"}>
              {t("zaraye.marketplace.blogs.breadcrumbs")}
            </Link>
          </span>
          <span className="pe-1 greater">
            <img src={ArrowLeft} width={14} alt="greater than" loading="lazy" />
          </span>
          <span className="activelink pe-1">
            <Link to>{blogData?.Title}</Link>
          </span>
        </div>
        <Row>
          <div className="col-md-12">
            <h2 className="text-center mb-4"> {blogData?.Title}</h2>
            <div className="d-block mt-5">
              <h5 className="blog-published-by">{blogData?.authorName}</h5>
              <p className="blog-published-date">{blogData?.publishedOn}</p>
            </div>
            <div className="d-block mb-5">
              <img src={blogData?.picture} className="w-100" loading="lazy" alt={blogData?.Title} />
            </div>
            <p dangerouslySetInnerHTML={{ __html: blogData?.Body }}></p>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default BlogDetailPage;

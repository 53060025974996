import React, { useState, useContext} from "react";
import HeadingDescription from "../SectionHeadingDescription";
import CustomButton from "../CustomBtn";
import LocalizationContext from "../../../Utils/LocalizationContext";
import { toast } from "react-toastify";
import "./style.css";
import Input from '../input';

const Newsletter = () => {
    const { t } = useContext(LocalizationContext);
    const [email, setEmail] = useState("");

    const handleSubscribeNow = () => {
        if (email.trim() === '') {
            return;
        } const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (reg.test(email) === false) {
            toast.error(t("zaraye.marketplace.pleaseentervalidemail.error"));
        }
        else {
            toast.success(t("zaraye.marketplace.youarenowsubscribe.text"));
            setEmail('')
        }
    }

    return (
        <div className="footer-newsletter">
            <div className="container">
                <div className="row d-flex align-items-center newsletter">
                    <div className="col-lg-4 ps-3 ps-md-0">
                        <HeadingDescription
                            headingText={t("zaraye.marketplace.stayinformed.text")}
                        />
                    </div>
                    <div className="col-lg-8 pe-0 ps-0 ps-lg-4 subscribe-input">
                        <div className="search-bar d-flex justify-content-between align-items-center">
                            <div className="search-field form-group">
                                <Input
                                    className={"form-control border-0 bg-transparent"}
                                    placeholder={t("zaraye.marketplace.emailaddress.placeholder")}
                                    type="email"
                                    value={email}
                                    maxLength={50}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    name={"Email Address"}
                                />
                            </div>
                            <CustomButton onClick={handleSubscribeNow} text={t('zaraye.marketplace.subscribenow.text')} className='primary-btn' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Newsletter;

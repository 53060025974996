import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Success from "../../../Assests/Images/Zaraye/mp-success.svg";
import Danger from "../../../Assests/Images/Zaraye/mp-danger.svg";
import WhatsApp from "../../../Assests/Images/MarketPlace/whatsapp-icon.svg";
import Button from "../CustomBtn";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import LocalizationContext from "../../../Utils/LocalizationContext";
import "./style.css";

const RealTimePrice = ({ data, showItemsCount }) => {
  const commodityData = useSelector(
    (state) => state.CommonReducer.commodityData
  );
  // const { data } = props;
  const loadAllRates = data;
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);
  const displayedData = data?.slice(0, showItemsCount);

  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  };

  const handleWhatsAppClick = () => {
    const phoneNumber = "+923162229990";
    const link = `https://wa.me/${phoneNumber}`;

    window.open(link, "_blank");
  };

  return (
    <>
      <div className="d-flex justify-content-between live-slider position-relative align-items-center real-time-slider">
        <p className="mb-0 ps-3">{t("zaraye.marketplace.live.text")}</p>
        <div className={`col-md-11 ps-0 commodity ${
          document.documentElement.dir === "rtl" ? "pe-md-3" : "ps-md-3"
        }`}>
          <Slider {...settings}>
            {commodityData.map((item, index) => (
              <div
                key={index}
                className="d-flex justify-content-between top-header"
              >
                <div className="d-flex currency">
                  <span>{item?.Name}:</span>
                  <span className="ps-1 sm-ps-2">${item?.Rate}</span>
                </div>
                <div className="d-flex justify-content-between ms-2">
                  {parseFloat(item?.Percentage?.replace("%", "")) > 0 ? (
                    <img src={Success} alt="Success" loading="lazy" />
                  ) : (
                    <img src={Danger} alt="Danger" loading="lazy" />
                  )}
                  <span
                    className={
                      parseFloat(item?.Percentage.replace("%", "")) > 0
                        ? "green ms-1"
                        : "red ms-1"
                    }
                  >
                    {item?.Percentage}
                  </span>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="table-cont">
        <table className="table table-borderless real-time-price">
          <thead>
            <tr>
              <th className="first">
                {t("zaraye.marketplace.realtime.table.product.text")}
              </th>
              <th className="second">
                {t("zaraye.marketplace.realtime.table.industry.text")}
              </th>
              <th className="second">
                {t("zaraye.marketplace.realtime.table.updated.text")}
              </th>
              <th className="second">
                {t("zaraye.marketplace.realtime.table.rate.text")}
              </th>
              <th className="last-th">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {loadAllRates?.map((item, index) => (
              <tr key={`load-all-rates-${index}`} className="cursor-pointer" onClick={() =>
                navigate(`/${item.SeName}`, {
                  state: {
                    ProductAttributesXML: item?.productattributesxml,
                    BrandId: item?.BrandId,
                  },
                })
              }>
                <td className="first">
                  {item?.product}
                  {item?.ProductAttributes?.map(
                    (item, index) => " " + item?.Value + " "
                  )}
                  {item?.brand}
                </td>
                <td className="second">{item?.industry}</td>
                <td className="second">{item?.updateOn}</td>
                <td className="second">{item?.formatPrice}</td>
                <td className="last-td">
                  {/* <Button
                    className="primary-btn view-product"
                    text={t("zaraye.marketplace.viewproduct.text")}
                    onClick={() =>
                      navigate(`/${item.SeName}`, {
                        state: {
                          ProductAttributesXML: item?.productattributesxml,
                          BrandId: item?.BrandId,
                        },
                      })
                    }
                  /> */}
                  <Button
                    className="primary-btn chat-now d-flex align-items-center"
                    text={t("zaraye.marketplace.whatsapp.text")}
                    onClick={handleWhatsAppClick}
                    icon={WhatsApp} 
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* responsive real time price divs */}
      <div className="real-time-insights-resp">
        {displayedData?.map((item, index) => (
          <div key={`load-all-resp-rates-${index}`} className="insights-items">
            <div className="d-flex justify-content-between align-items-center w-100">
              <span className="product-name">
                <img src={item?.picture} alt={item?.industry} loading="lazy" />
                {item?.product}
                {item?.ProductAttributes?.map(
                  (item, index) => " " + item?.Value + " "
                )}
                {item?.brand}
              </span>
              <span className="industry-name">{item?.industry}</span>
            </div>
            <div className="d-flex justify-content-between d-flex justify-content-between align-items-center w-100 py-1">
              <span className="updated-ago">
                <span className="updated pe-2">
                  {t("zaraye.marketplace.updated:.text")}
                </span>
                {item?.updateOn}
              </span>
              <span className="updated-ago">{item?.formatPrice}</span>
            </div>
            <div className="d-flex justify-content-end pt-2">
              {/* <Button
                className="primary-btn view-product me-3"
                text={t("zaraye.marketplace.viewproduct.text")}
                onClick={() => navigate(`${item.SeName}`)}
              /> */}
              <Button
                className="primary-btn chat-now"
                text={t("zaraye.marketplace.whatsapp.text")}
                onClick={handleWhatsAppClick}
                icon={WhatsApp} 
              />
            </div>
          </div>
        ))}
      </div>
      <Link
        to={`/${"realtimepricing"}`}
        className="text-decoration-underline uppercase all-products"
      >
        {t("zaraye.marketplace.viewfullpricelist.link")} {">>"}
      </Link>
    </>
  );
};

export default RealTimePrice;

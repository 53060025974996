import React, { useContext, useState } from "react";
import LandingPagesMainTitle from "../../../Components/Zaraye/LandingPagesTitle";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import CustomAccordion from "../../../Components/Common/Accordion";
import GetZarayeApp from "../../../Components/Common/getZarayeApp";
import { useSelector } from 'react-redux';
import LocalizationContext from "../../../Utils/LocalizationContext";

const FaqsPage = () => {
  const {t} = useContext(LocalizationContext)
  const allFaqs = useSelector((state) => state.CommonReducer.allFaqs);

  const [active, setActive] = useState(0);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(0);
    } else {
      setActive(index);
    }
  };

  return (
    <>
      <Container>
        <div className="breadcrumb">
          <span className="pe-1 unactive">
            <Link to={"/"}>{t('zaraye.marketplace.home.breadcrumbs')}</Link>
          </span>
          <span className="pe-1 greater">
            <img src={ArrowLeft} width={14} alt="greater than" loading="lazy" />
          </span>
          <span className="activelink">{t('zaraye.marketplace.faqs.breadcrumbs')}</span>
        </div>
        <Row>
          <LandingPagesMainTitle
            headingText={t('zaraye.marketplace.zarayefaqcenter.main.title')}
            descriptionOne={t('zaraye.marketplace.zarayefaqcenter.main.description')}
          />
        </Row>
        <div className="faqs-container">
          {allFaqs?.map((item, index) => {
            return (
              <CustomAccordion
                key={`all-faqs-${index}`}
                active={active}
                handleToggle={handleToggle}
                question={item?.Question}
                answer={item?.Answer}
              />
            );
          })}
        </div>
      </Container>
      <GetZarayeApp />
    </>
  );
};

export default FaqsPage;

import * as ActionTypes from '../../Actions/ActionTypes';

const initialState = {
    languageId: 1,
    languages: [],
    language: {},
    loading: false,
    industries: [],
    allSlugs: [],
    industriesAndCategories: [],
    allBrands: [],
    latestBlogs: [],
    allBlogs: [],
    allTopCategories:[],
    productFilters: {},
    products: {},
    countries: [],
    allTopWebSliders: [],
    allRates:[],
    allFaqs: [],
    allBestSellingProducts: [],
    commodityData: [],
    allCategories: [],
}

const CommonReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SHOW_LOADER:
            return {
                ...state,
                loading: true
            };
        case ActionTypes.HIDE_LOADER:
            return {
                ...state,
                loading: false
            };
        case ActionTypes.LOAD_INDUSTRIES:
            return {
                ...state,
                industries: action.data
            };
        case ActionTypes.SAVE_LANGUAGE_ID:
            return {
                ...state,
                languageId: action.data
            }
        case ActionTypes.SAVE_LANGUAGE:
            return {
                ...state,
                language: action.data
            }
        case ActionTypes.LOAD_LANGUAGES:
            return {
                ...state,
                languages: action.data
            };
        case ActionTypes.LOAD_ALL_SLUG:
            return {
                ...state,
                allSlugs: action.data
            };
        case ActionTypes.LOAD_INDUSTRIES_AND_CATEGORIES:
            return {
                ...state,
                industriesAndCategories: action.data
            };
        case ActionTypes.LOAD_ALL_BRANDS:
            return {
                ...state,
                allBrands: action.data
            };
        case ActionTypes.LOAD_LATEST_BLOGS:
            return {
                ...state,
                latestBlogs: action.data
            };
        case ActionTypes.LOAD_ALL_TOP_CATEGORIES:
            return {
                ...state,
                allTopCategories: action.data
            };
        case ActionTypes.LOAD_ALL_PRODUCT_FILTER:
            return {
                ...state,
                productFilters: action.data
            };
        case ActionTypes.LOAD_ALL_PRODUCTS:
            return {
                ...state,
                products: action.data
            };
        case ActionTypes.LOAD_ALL_COUNTRIES:
            return {
                ...state,
                countries: action.data
            };
        case ActionTypes.LOAD_ALL_BLOGS:
            return {
                ...state,
                allBlogs: action.data
            };
        case ActionTypes.LOAD_ALL_WEB_SLIDER:
            return {
                ...state,
                allTopWebSliders: action.data
            };
        case ActionTypes.LOAD_ALL_RATES:
            return {
                ...state,
                allRates: action.data
            };
        case ActionTypes.LOAD_ALL_FAQS:
            return {
                ...state,
                allFaqs: action.data
            };
        case ActionTypes.LOAD_ALL_BEST_SELLING_PRODUCTS:
            return {
                ...state,
                allBestSellingProducts: action.data
            };
        case ActionTypes.LOAD_ALL_COMMODITY_DATA:
            return {
                ...state,
                commodityData: action.data
            };
        case ActionTypes.LOAD_ALL_EXPLORE_CATEGORIES_FILTER:
            return {
                ...state,
                allCategories: action.data
            };
        default:
            return state;
    }
};

export default CommonReducer;
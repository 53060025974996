import React, { useContext } from "react";
import HeadingDescription from "../../../Components/Common/SectionHeadingDescription";
import BrandSlider from "../../../Components/Zaraye/BrandSlider";
import Pricing from "../../../Assests/Images/Common/Pricing.svg";
import ReadyToShip from "../../../Assests/Images/Common/ready-to-ship.svg";
import Logistics from "../../../Assests/Images/Common/logistics-service.svg";
import { ReactComponent as PreviousDark } from "../../../Assests/Images/Zaraye/previous.svg";
import { ReactComponent as NextDark } from "../../../Assests/Images/Zaraye/next.svg";
import LocalizationContext from "../../../Utils/LocalizationContext";
import { Row } from "react-bootstrap";
import "./style.css";

const ManufacturesAndBrands = ({ data = [], hasSlider }) => {
  const { t } = useContext(LocalizationContext);

  const settings = {
    centerMode: true,
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    nextArrow: <NextDark />,
    prevArrow: <PreviousDark />,
    slidesToShow: 8,
    slidesToScroll: 1,
    speed: 2000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1680,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 3,
          // arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          centerMode: false,
          infinite: true,
          autoplay: false,
          arrows: true,
          speed: 700,
          autoplaySpeed: 500,
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <div
      className={`container-fluid zaraye-core-values manu-brands ${
        data?.length === 0 && "pb-5"
      }`}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 manu-brands-res">
            <div className="row justify-content-between about-brand-slider-section text-center mx-0">
              <div className="col-md-4 brands-box">
                <Row className="align-items-center justify-content-between brands-box-item">
                  <div className="col-lg-3 col-md-6 col-sm-4 col-2">
                    <img src={Pricing} alt="Pricing" loading="lazy" />
                  </div>
                  <div className="col-lg-9 col-md-12 col-sm-8 col-10 text-start cart-item-resp">
                    <HeadingDescription
                      headingText={t("zaraye.marketplace.requestaquote.text")}
                      descriptionOne={t(
                        "zaraye.marketplace.requestaquote.description"
                      )}
                    />
                  </div>
                </Row>
              </div>
              <div className="col-md-4 brands-box">
                <Row className="align-items-center justify-content-between brands-box-item">
                  <div className="col-lg-3 col-md-6 col-sm-4 col-2">
                    <img src={ReadyToShip} alt="Pricing" loading="lazy" />
                  </div>
                  <div className="col-lg-9 col-md-12 col-sm-8 col-10 text-start cart-item-resp">
                    <HeadingDescription
                      headingText={t("zaraye.marketplace.readytoship.text")}
                      descriptionOne={t(
                        "zaraye.marketplace.readytoship.decription"
                      )}
                    />
                  </div>
                </Row>
              </div>
              <div className="col-md-4 brands-box">
                <Row className="align-items-center justify-content-between brands-box-item">
                  <div className="col-lg-3 col-md-6 col-sm-4 col-2">
                    <img src={Logistics} alt="Pricing" loading="lazy" />
                  </div>
                  <div className="col-lg-9 col-md-12 col-sm-8 col-10 text-start cart-item-resp">
                    <HeadingDescription
                      headingText={t(
                        "zaraye.marketplace.logisticsservices.text"
                      )}
                      descriptionOne={t(
                        "zaraye.marketplace.logisticsservices.description"
                      )}
                    />
                  </div>
                </Row>
              </div>
            </div>
          </div>
          {/* <div className="col-xxl-9 col-lg-12 mx-auto text-center cores"> */}
          <Row className="justify-content-center align-items-center mx-auto text-center cores">
            <div className="col-xxl-10 col-lg-12">
              <h2 className="section-heading">
                {t("zaraye.marketplace.trustedmanufacturersandbrands.text")}
              </h2>
            </div>
          </Row>
          <Row className="justify-content-center align-items-center mx-auto text-center cores">
            <div className="col-xxl-7 col-lg-8">
              <p className="section-description mt-2">
                {t(
                  "zaraye.marketplace.trustedmanufacturersandbrands.description"
                )}{" "}
              </p>
            </div>
          </Row>
          {/* <HeadingDescription
                                    headingText="Trusted Manufacturers and Brands"
                                    descriptionOne="Discover a curated selection of products from our esteemed network of trusted manufacturers and renowned brands"
                                /> */}
          {/* </div> */}
        </div>
      </div>
      <BrandSlider data={data} settings={settings} showDescriptions={false} />
    </div>
  );
};

export default ManufacturesAndBrands;

import RestClient from '../RestClient';

const GetshoppingCartItem = () => {
    return RestClient.Get(`shopping-cart/get-all-cart-items`, false);
}

const AddItemToCart = (payload) => {
    return RestClient.Post(`shopping-cart/add-cart-item`, payload);
}

const UpdateCartItemQuatity = (id, quantity) => {
    return RestClient.Put(`shopping-cart/update-cart-item?cartItemId=${id}&quantity=${quantity}`, false);
}

const DeleteCartItem = (id) => {
    return RestClient.Delete(`shopping-cart/delete-cart-item?cartItemId=${id}`, false);
}

const exportedObject = {
    GetshoppingCartItem,
    AddItemToCart,
    UpdateCartItemQuatity,
    DeleteCartItem
};

export default exportedObject;
import CommonService from "../../Services/Common/CommonService";
import * as ActionTypes from "../ActionTypes";

export const showLoader = (text) => {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SHOW_LOADER,
      loading: true,
    });
  };
};

export const hideLoader = (text) => {
  return (dispatch, getState) => {
    dispatch({
      type: ActionTypes.HIDE_LOADER,
      loading: false,
    });
  };
};

export const fetchIndustries = () => {
  return async (dispatch, getState) => {
    var response = await CommonService.GetAllIndustries();
    if (response.success) {
      dispatch({
        type: ActionTypes.LOAD_INDUSTRIES,
        data: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.LOAD_INDUSTRIES,
        data: [],
      });
    }
  };
};

export const fetchLanguages = () => {
  return async (dispatch, getState) => {
    var response = await CommonService.GetAllLanguages();
    if (response.success) {
      dispatch({
        type: ActionTypes.LOAD_LANGUAGES,
        data: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.LOAD_LANGUAGES,
        data: [],
      });
    }
  };
};

export const fetchAllSlug = () => {
  return async (dispatch, getState) => {
    var response = await CommonService.GetAllSlugName();
    if (response.success) {
      dispatch({
        type: ActionTypes.LOAD_ALL_SLUG,
        data: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.LOAD_ALL_SLUG,
        data: [],
      });
    }
  };
};

export const fetchBestSellingProducts = () => {
  return async (dispatch, getState) => {
    var response = await CommonService.GetAllBestSellingProducts();
    if (response.success) {
      dispatch({
        type: ActionTypes.LOAD_ALL_BEST_SELLING_PRODUCTS,
        data: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.LOAD_ALL_BEST_SELLING_PRODUCTS,
        data: [],
      });
    }
  };
};

export const saveLanguage = (language) => {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SAVE_LANGUAGE,
      data: language,
    });
  };
};

export const saveLanguageId = (id) => {
  return async (dispatch, getState) => {
    dispatch({
      type: ActionTypes.SAVE_LANGUAGE_ID,
      data: id,
    });
  };
};

export const fetchIndustriesAndCategories = () => {
  return async (dispatch, getState) => {
    var response = await CommonService.GetIndustriesAndCategories();
    if (response.success) {
      dispatch({
        type: ActionTypes.LOAD_INDUSTRIES_AND_CATEGORIES,
        data: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.LOAD_INDUSTRIES_AND_CATEGORIES,
        data: [],
      });
    }
  };
};

export const fetchAllBrands = () => {
  return async (dispatch, getState) => {
    var response = await CommonService.GetAllBrands();
    if (response.success) {
      dispatch({
        type: ActionTypes.LOAD_ALL_BRANDS,
        data: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.LOAD_ALL_BRANDS,
        data: [],
      });
    }
  };
};

export const fetchLatestBlog = (noofblogs = 4) => {
  return async (dispatch, getState) => {
    var response = await CommonService.GetLatestBlog(noofblogs);
    if (response.success) {
      dispatch({
        type: ActionTypes.LOAD_LATEST_BLOGS,
        data: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.LOAD_LATEST_BLOGS,
        data: [],
      });
    }
  };
};

export const fetchAllBlogs = (pageIndex = 0, pageSize = 5) => {
  return async (dispatch, getState) => {
    var response = await CommonService.GetAllBlogs(pageIndex, pageSize);
    if (response.success) {
      dispatch({
        type: ActionTypes.LOAD_ALL_BLOGS,
        data: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.LOAD_ALL_BLOGS,
        data: [],
      });
    }
  };
};

export const fetchAllTopCategories = () => {
  return async (dispatch, getState) => {
    var response = await CommonService.GetAllTopCAtegories();
    if (response.success) {
      dispatch({
        type: ActionTypes.LOAD_ALL_TOP_CATEGORIES,
        data: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.LOAD_ALL_TOP_CATEGORIES,
        data: [],
      });
    }
  };
};

export const fetchAllFaqs = () => {
  return async (dispatch, getState) => {
    var response = await CommonService.GetAllFaqs();
    if (response.success) {
      dispatch({
        type: ActionTypes.LOAD_ALL_FAQS,
        data: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.LOAD_ALL_FAQS,
        data: [],
      });
    }
  };
};

export const fetchAllWebSlider = () => {
  return async (dispatch, getState) => {
    var response = await CommonService.GetAllWebSlider();
    if (response.success) {
      dispatch({
        type: ActionTypes.LOAD_ALL_WEB_SLIDER,
        data: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.LOAD_ALL_WEB_SLIDER,
        data: [],
      });
    }
  };
};

export const fetchAllProductFilters = () => {
  return async (dispatch, getState) => {
    var response = await CommonService.GetAllProductFilters();
    if (response.success) {
      dispatch({
        type: ActionTypes.LOAD_ALL_PRODUCT_FILTER,
        data: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.LOAD_ALL_PRODUCT_FILTER,
        data: {},
      });
    }
  };
};

export const fetchCountry = () => {
  return async (dispatch, getState) => {
    var response = await CommonService.GetAllCountries();
    if (response.success) {
      dispatch({
        type: ActionTypes.LOAD_ALL_COUNTRIES,
        data: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.LOAD_ALL_COUNTRIES,
        data: [],
      });
    }
  };
};

export const fetchAllRates = () => {
  return async (dispatch, getState) => {
    var response = await CommonService.GetAllRates();
    if (response.success) {
      dispatch({
        type: ActionTypes.LOAD_ALL_RATES,
        data: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.LOAD_ALL_RATES,
        data: [],
      });
    }
  };
};

export const fetchCommodityData = () => {
  return async (dispatch, getState) => {
    var response = await CommonService.GetCommodityData();
    if (response.success) {
      dispatch({
        type: ActionTypes.LOAD_ALL_COMMODITY_DATA,
        data: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.LOAD_ALL_COMMODITY_DATA,
        data: [],
      });
    }
  };
};

export const fetchProducts = (
  industryIds = "",
  categoriesIds = "",
  brandsIds = "",
  pageSize = 12,
  pageIndex = 0,
  orderBy = ""
) => {
  return async (dispatch, getState) => {
    var payload = {
      industryIds: industryIds,
      categoriesIds: categoriesIds,
      brandsIds: brandsIds,
      pageSize: pageSize,
      pageIndex: pageIndex,
      orderBy: orderBy,
    };
    var response = await CommonService.GetProducts(payload);
    if (response.success) {
      dispatch({
        type: ActionTypes.LOAD_ALL_PRODUCTS,
        data: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.LOAD_ALL_PRODUCTS,
        data: {},
      });
    }
  };
};

export const fetchAllCategories = (
  industryIds = "",
  pageSize = 12,
  pageIndex = 0,
  orderBy = ""
) => {
  return async (dispatch, getState) => {
    var payload = {
      industryIds: industryIds,
      pageSize: pageSize,
      pageIndex: pageIndex,
      orderBy: orderBy,
    };
    var response = await CommonService.GetAllCategories(payload);
    if (response.success) {
      dispatch({
        type: ActionTypes.LOAD_ALL_EXPLORE_CATEGORIES_FILTER,
        data: response.data,
      });
    } else {
      dispatch({
        type: ActionTypes.LOAD_ALL_EXPLORE_CATEGORIES_FILTER,
        data: {},
      });
    }
  };
};

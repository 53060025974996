import React, { useContext } from "react";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import GetQuoteForm from "../../../Components/Common/GetQuoteForm";
import RealTimePrice from "../../../Components/Common/RealTimePrice";
import HeadingDescription from "../../../Components/Common/SectionHeadingDescription";
import OurProducts from "../../../Components/Common/Products";
import { useSelector } from "react-redux";
import CommonService from "../../../Services/Common/CommonService";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import { ReactComponent as PreviousDark } from "../../../Assests/Images/Zaraye/previous.svg";
import { ReactComponent as NextDark } from "../../../Assests/Images/Zaraye/next.svg";
import ManufacturesAndBrands from "../../../Components/Common/ManufacturesAndBrands";
import OurCustomersSlider from "../../../Components/Common/OurCustomersSlider";
import GetZarayeApp from "../../../Components/Common/getZarayeApp";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import LocalizationContext from "../../../Utils/LocalizationContext";
import "./index.css";

const IndustryDetailPage = ({ item }) => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const loadAllRates = useSelector((state) => state.CommonReducer?.allRates);
  const languageId = useSelector((state) => state.CommonReducer?.languageId);

  const [industryData, setIndustryData] = useState({});

  const getIndustryDetail = async (id) => {
    var response = await CommonService.GetIndustriesDetail(id);
    if (response?.success) {
      setIndustryData(response?.data);
    }
  };

  const slidesToShow = Math.min(industryData?.Categories?.length, 5);
  
  useEffect(() => {
    getIndustryDetail(item?.Id);
  }, [item?.Id, languageId]);
  
  const settings = {
    dots: false,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    nextArrow: <NextDark />,
    prevArrow: <PreviousDark />,
    infinite: industryData?.Categories?.length > slidesToShow,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          autoplay: false,
        },
      },
    ],
  };

  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <meta
          data-react-helmet="true"
          name="description"
          content={item?.MetaDescription ? item?.MetaDescription : ""}
        />
        <meta
          data-react-helmet="true"
          name="keywords"
          content={item?.MetaKeywords ? item?.MetaKeywords : ""}
        />
        <title>
          {item?.MetaTitle
            ? item?.MetaTitle
            : t("zaraye.marketplace.zarayeb2brawmaterialplatform.metatitle")}
        </title>
        {item?.Slug && (
          <link
            rel="canonical"
            href={`https://www.react-app.zaraye.co/${item?.Slug}`}
          />
        )}
      </Helmet>
      <section className="industry-landing-page">
        <Container className="mp-hero-container">
          <div className="breadcrumb">
            <span className="pe-1 unactive">
              <Link to={"/"}>{t("zaraye.marketplace.home.breadcrumbs")}</Link>
            </span>
            <span className="pe-1 greater">
              <img
                src={ArrowLeft}
                width={14}
                alt="greater than"
                loading="lazy"
              />
            </span>
            <span className="pe-1 unactive">
              <Link to={"/"}>
                {t("zaraye.marketplace.industry.breadcrumbs")}
              </Link>
            </span>
            <span className="pe-1 greater">
              <img
                src={ArrowLeft}
                width={14}
                alt="greater than"
                loading="lazy"
              />
            </span>
            <span className="activelink">
              {item?.Title}
            </span>
          </div>
          <Row>
            <div className="col-lg-8 col-xl-9">
              <div className="hero-industies-slider-row position-relative">
                <div className="his-img h-100">
                  <img
                    src={industryData?.Picture}
                    alt={industryData?.Name}
                    loading="lazy"
                  />
                </div>
                <Row className="his-content">
                  <div className="col-lg-9 col-xl-7">
                    <div
                      onClick={() =>
                        navigate(`${"/product"}`, {
                          state: {
                            industryId: item?.Id,
                          },
                        })
                      }
                    >
                      <h1 className="text-white">{industryData?.Name}</h1>
                      <p
                        className="text-white"
                        dangerouslySetInnerHTML={{
                          __html: industryData?.Description,
                        }}
                      ></p>
                      <h1 className="explore-text">{t('zaraye.marketplace.exploreproducts.text')}</h1>
                    </div>
                  </div>
                </Row>
              </div>
            </div>
            <div className="col-lg-4 col-xl-3">
              <GetQuoteForm
                source={industryData?.Name}
                formTitle={t("zaraye.marketplace.customquote.text")}
                formDescription={t(
                  "zaraye.marketplace.customquote.description"
                )}
              />
            </div>
          </Row>
        </Container>
        {loadAllRates.filter((x) => x?.IndustryId === item?.Id).length > 0 && (
          <Container className="textile-price-table">
            <Row>
              {/* <div className="col-md-12"> */}
              <HeadingDescription
                headingText={t(
                  "zaraye.marketplace.industrypriceinpakistan.text",
                  { industryName: industryData?.Name }
                )}
                descriptionOne={t(
                  "zaraye.marketplace.industrypriceinpakistan.description",
                  { industryName: industryData?.Name }
                )}
              />
              {loadAllRates.filter((x) => x?.IndustryId === item?.Id).length >
                0 && (
                  <RealTimePrice
                    data={loadAllRates.filter((x) => x?.IndustryId === item?.Id)}
                    showItemsCount={5}
                  />
                )}
              {/* </div> */}
            </Row>
          </Container>
        )}
        <Container className="explore-categories-section position-relative">
          <Row className="heading-bb mb-5">
            <div className="col-md-8">
              <h2 className="section-heading">{t("zaraye.marketplace.exploreindustrycategories.text", { industryName: industryData?.Name })}</h2>
              <p className={`text-center ${document.documentElement.dir === "rtl" ? "text-md-end" : "text-md-start"}`}>{t("zaraye.marketplace.exploreindustrycategories.description", { industryName: industryData?.Name })}</p>
            </div>
          </Row>
          <Row>
            <div className="selling-products-slider category-slider">
              <Slider {...settings}>
                {industryData?.Categories?.map((item, index) => (
                  <div key={`industrydata-categories-${index}`} className="card products">
                    <Link
                      to={`/${item?.SeName}`}
                      className="text-decoration-none inner-products"
                    >
                      <div className="position-relative prod-box-height cat-thumbnail">
                        <img
                          src={item?.Picture}
                          alt={item?.Name}
                          className="w-100 cat-img-fix"
                          loading="lazy"
                        />
                      </div>
                      <h5 className="mb-0">{item?.Name}</h5>
                      <h6 className="mt-2">
                        {t(
                          "zaraye.marketplace.explorecategories.linktext"
                        )}
                      </h6>
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
            {/* <OurProducts
              data={industryData?.Categories}
              headingText={t(
                "zaraye.marketplace.exploreindustrycategories.text",
                { industryName: industryData?.Name }
              )}
              descriptionOne={t(
                "zaraye.marketplace.exploreindustrycategories.description",
                { industryName: industryData?.Name }
              )}
            /> */}
          </Row>
        </Container>
        {industryData?.BestSellingProducts?.length >0 && (
          <Container className="discover-best-selling-section">
            <Row>
              <OurProducts
                data={industryData?.BestSellingProducts}
                headingText={t(
                  "zaraye.marketplace.topsellingindustryproducts.text",
                  { industryName: industryData?.Name }
                )}
                descriptionOne={t(
                  "zaraye.marketplace.topsellingindustryproducts.text",
                  { industryName: industryData?.Name }
                )}
              />
            </Row>
          </Container>
        )}
        <section className="position-relative mt-5">
          <ManufacturesAndBrands data={industryData?.Brands} />
        </section>
        <Container className="featured-product-section">
          <Row>
            <OurProducts
              data={industryData?.Products}
              headingText={t(
                "zaraye.marketplace.featuredindustryproduct.text",
                { industryName: industryData?.Name }
              )}
              descriptionOne={t(
                "zaraye.marketplace.featuredindustryproduct.description",
                { industryName: industryData?.Name }
              )}
            />
          </Row>
        </Container>
        <OurCustomersSlider />
        <GetZarayeApp />
      </section>
    </>
  );
};

export default IndustryDetailPage;

import React, { useContext } from "react";
import { Container } from 'react-bootstrap';
import GetQuoteForm from '../../Common/GetQuoteForm';
import HeroCategoriesList from '../HeroCategoriesList';
import HeroIndustrySlider from '../HeroIndustrySlider';
import { Link } from 'react-router-dom';
import LocalizationContext from "../../../Utils/LocalizationContext";
import './style.css';

const HeroSection = () => {
    const { t } = useContext(LocalizationContext);

    return (
        <Container className='mp-hero-container'>
            <div className='d-flex flex-column flex-md-row justify-content-between'>
                <div className="hero-col-1 top-categories-colum">
                    <h2>{t("zaraye.marketplace.topcategories.text")}</h2>
                    <div className="categories-list">
                        <HeroCategoriesList />
                    </div>
                    <div className="explore position-relative mt-3">
                        <p className='mb-0 expolre-categories'>
                            <Link to={"/category"}>
                                {t('zaraye.marketplace.exploreallcategories.text')}
                            </Link>
                        </p>
                    </div>
                </div>
                <div className="hero-col-2">
                    <HeroIndustrySlider />
                </div>
                <div className="hero-col-3">
                    <GetQuoteForm source={t('zaraye.marketplace.web.source')} formTitle={t('zaraye.marketplace.customquote.text')} formDescription={t("zaraye.marketplace.customquote.description")} />
                </div>
            </div>
        </Container>
    )
}

export default HeroSection

import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import { useContext, useEffect, useState } from "react";
import * as commonAction from "../../../Actions/Common/CommonAction";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "../../../Assests/Images/Common/close-dark.svg";
import FilterIcon from "../../../Assests/Images/Common/filter.svg";
import LocalizationContext from "../../../Utils/LocalizationContext";
import Pagination from "../../../Components/Common/Pagination";
import "./style.css";

const CategoryPage = () => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();

  const industriesList = useSelector((state) => state.CommonReducer.industries);
  const categoryData = useSelector((state) => state.CommonReducer.allCategories);

  const [industryFilters, setIndustryFilters] = useState(industriesList);
  const [pageIndex, setPageIndex] = useState(categoryData?.PageIndex);
  const [isRunning, setIsRunning] = useState(true);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);



  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
    document.body.style.overflow = isFilterVisible ? "auto" : "hidden";
  };

  const toggleCheckbox = (id, checked) => {
    var industryData = Object.assign([], industryFilters);
    var temp = industryData.find((x) => x.Value === id);
    temp.Checked = checked;
    setPageIndex(0);
    setIsRunning(false);
    setIndustryFilters(industryData);

    // Update selected categories
    const updatedCategories = industryData
      .filter((x) => x.Checked)
      .map((x) => x.Text);
    setSelectedCategories(updatedCategories);
  };

  const removeSelectedCategory = (category) => {
    const updatedIndustryFilters = [...industryFilters];
    const categoryToUncheck = updatedIndustryFilters.find((x) => x.Text === category);
    if (categoryToUncheck) {
      categoryToUncheck.Checked = false;
      setIndustryFilters(updatedIndustryFilters);

      // Update selected categories
      const updatedCategories = updatedIndustryFilters
        .filter((x) => x.Checked)
        .map((x) => x.Text);
      setSelectedCategories(updatedCategories);
    }
  };

  const applyFilters = () => {
    var industryIds = industryFilters
      ?.filter((x) => x?.Checked === true)
      ?.map((x) => x.Value)
      ?.join(",");
    dispatch(commonAction.fetchAllCategories(industryIds, 12, pageIndex, ""));
  };

  const handlePageChange = (value) => {
    setIsRunning(false);
    setPageIndex(value);
  };

  useEffect(() => {
    if (!isRunning) {
      applyFilters();
    }
  }, [industryFilters, pageIndex]);

  return (
    <Container className="category-listing-pg product-list-pg">
      <div className="breadcrumb">
        <span className="pe-1 unactive">
          <Link to={"/"}>{t("zaraye.marketplace.home.breadcrumbs")}</Link>
        </span>
        <span className="pe-1 greater">
          <img src={ArrowLeft} width={14} alt="greater than" loading="lazy" />
        </span>
        <span className="activelink">
          {t("zaraye.marketplace.categories.breadcrumbs")}
        </span>
      </div>
      <Row>
        <div className={`col-md-4 col-lg-3 product-filter-column ${isFilterVisible ? "show" : ""}`}>
          <div className={`mobile-filter-header ${isFilterVisible ? "d-block" : "d-none"}`}>
            <h2>{t("zaraye.marketplace.filter.text")}</h2>

            <button className="close-filter" onClick={toggleFilterVisibility}>
              {" "}
              {t("zaraye.marketplace.close.text")}
              <img
                src={CloseIcon}
                alt="Close"
                width={12}
                loading="lazy"
                className="ms-1"
              />
            </button>
          </div>

          <div className="categories-list filter-sidebar filter-cont h-100">
            <h2 className="category-title">
              {t("zaraye.marketplace.industries.text")}
            </h2>

            <div className="industries-list">
            {industryFilters?.map((item, index) => (
              <div
                key={`industry-list-${index}`}
                className="mb-3 cursor-pointer"
              >
                  <div
                    className={`d-flex align-items-center ct-list 
                ${item?.Checked ? "selected-filter" : ""}`}
                    onClick={() => toggleCheckbox(item?.Value, !item?.Checked)}
                  >
                    <input
                      type="checkbox"
                      id={`custom-checkbox-${index}`}
                      checked={item?.Checked}
                      onChange={() => toggleCheckbox(item?.Value, !item?.Checked)}
                      className="custom-checkbox"
                    />
                    <label
                      htmlFor={`custom-checkbox-${index}`}
                      className="custom-checkbox-label"
                    ></label>
                    <div className="ct-list-img">
                      <img
                        src={item.Picture}
                        alt={item.Text}
                        className="w-100"
                        loading="lazy"
                      />
                    </div>
                    <div className="ct-list-title">
                      <p className="mb-0">{item.Text}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-md-8 col-lg-9 position-relative">
          <Row className="mb-3">
            <div className="col-md-12 d-flex justify-content-between align-items-center">
              <div className="grid-layout d-flex align-items-center">
                <div className="filter-page-size">
                  <p className="text-dark mb-0">
                    {t("zaraye.marketplace.showing.text")}
                    <span className="fw-bold"> {categoryData?.Categories?.length} </span>
                    {t("zaraye.marketplace.itemsoftotal.text")}
                    <span className="fw-bold"> {categoryData?.TotalCount} </span>
                    {t("zaraye.marketplace.items.text")}
                  </p>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <div className="selected-industry-tabs">
              <button
                onClick={toggleFilterVisibility}
                className="d-flex d-md-none align-items-center mobile-filter-btn"
              >
                <img
                  src={FilterIcon}
                  alt="Close"
                  // width={22}
                  className="me-1"
                />
                {t("zaraye.marketplace.filter.text")}
              </button>
            {/* <div className="selected-categories"> */}
              {selectedCategories.map((category, index) => (
                <span key={index} className="selected-ind">
                  {category}
                  <button
                    className="close-icon"
                    onClick={() => removeSelectedCategory(category)}
                  >
                    &times;
                  </button>
                </span>
              ))}
            {/* </div> */}
            </div>
            <div className="col-md-12 d-flex flex-wrap grid-product">
              {categoryData?.Categories?.map((item, index) => (
                <div key={`categories-${index}`} className="card products">
                  <Link
                    to={`/${item?.SeName}`}
                    className="text-decoration-none inner-products"
                  >
                    <div className="position-relative prod-box-height cat-thumbnail">
                      <img
                        src={item?.Picture}
                        alt={item?.Name}
                        className="w-100 cat-img-fix"
                        loading="lazy"
                      />
                    </div>
                    <h5 className="mb-0 mt-3">{item?.Name}</h5>
                    <h6 className="mt-2">
                      {t("zaraye.marketplace.explorecategories.linktext")}
                    </h6>
                  </Link>
                </div>
              ))}
            </div>
          </Row>
        </div>
      </Row>
      {categoryData?.TotalPages > 1 && (
        <div className="pagination-box pe-4">
          <Pagination
            HasNextPage={categoryData?.HasNextPage}
            HasPreviousPage={categoryData?.HasPreviousPage}
            currentPage={pageIndex}
            totalPages={categoryData?.TotalPages}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </Container>
  );
};

export default CategoryPage;

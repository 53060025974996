import React, { useContext } from "react";
import ZarayeGlobalLogo from "../../../Assests/Images/Global/zaraye-global-logo.png";
import Button from "../../Common/CustomBtn";
import Search from "../../../Assests/Images/Zaraye/search.svg"
import { Link } from "react-router-dom";
import LocalizationContext from "../../../Utils/LocalizationContext";
import CustomButton from "../../Common/CustomBtn";
import { useNavigate } from 'react-router-dom';

import "./style.css";

const Header = () => {
  const {t} = useContext(LocalizationContext)
  const navigate = useNavigate();

  return (
    <div className="container position-relative">
      <div className="d-flex justify-content-between align-items-center main-header">
        <Link to={'/global'}>
          <img src={ZarayeGlobalLogo} alt="Zaraye" className="zaraye-global-img" loading="lazy" />
        </Link>
        <div className="col-md-6 d-flex align-items-center justify-content-end">
        <CustomButton text={t('zaraye.marketplace.reachustonow.text')} className='primary-btn header-btn cursor-pointer d-none d-md-block' onClick={() => navigate('/')} />
          {/* <Button className="primary-btn header-btn cursor-pointer d-none d-md-block" text={t('zaraye.marketplace.reachustonow.text')} /> */}
          {/* <img src={Search} alt="Search" className="cursor-pointer px-3" loading="lazy" /> */}
        </div>
      </div>
    </div>
  );
};

export default Header;

import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../../Pages/Marketplace/Home";
import IndustryDetailPage from "../../Pages/Marketplace/IndustryDetailPage";
import Footer from "../../Components/Marketplace/Footer";
import Header from "../../Components/Marketplace/Header";
import CategoryDetailPage from "../../Pages/Marketplace/CategoryDetailPage";
import ProductDetailPage from "../../Pages/Marketplace/ProductDetailPage";
import BrandDetailPage from "../../Pages/Marketplace/BrandDetailPage";
import BlogDetailPage from "../../Pages/Marketplace/BlogDetailPage";
import { useSelector } from "react-redux";
import TopicDetailPage from "../../Pages/Marketplace/TopicDetailPage";
import BlogPage from "../../Pages/Marketplace/Blog";
import SitemapPage from "../../Pages/Marketplace/Sitemap";
import ProductPage from "../../Pages/Marketplace/ProductList";
import CategoryPage from "../../Pages/Marketplace/Category";
import BrandPage from "../../Pages/Marketplace/Brand";
import SolutionPage from "../../Pages/Marketplace/Solution";
import RealTimePricePage from "../../Pages/Marketplace/RealTimePrice";
import ContactUsPage from "../../Pages/Marketplace/ContactUs";
import CartDetailPage from "../../Pages/Marketplace/CartDetailPage";
import ThankYouPage from "../../Pages/Marketplace/ThankYou";
import BecomeASupplier from "../../Pages/Marketplace/BecomeSupplier";
import FaqsPage from "../../Pages/Marketplace/Faqs";
import CareerPage from "../../Pages/Marketplace/Career";
import GetAPolymerQuote from "../../Pages/Marketplace/SeoLandingPages/GetAPolymerQuote";
import GetAConstructionQuote from "../../Pages/Marketplace/SeoLandingPages/GetAConstructionQuote";
import GetAFinishedGoodsQuote from "../../Pages/Marketplace/SeoLandingPages/GetAFinishedGoodsQuote";
import GetATextileQuote from "../../Pages/Marketplace/SeoLandingPages/GetATextileQuote";
import GetAQuote from "../../Pages/Marketplace/SeoLandingPages/GetAQuote";
import CompanyPage from "../../Pages/Marketplace/Company";
import RequestCompletePage from "../../Pages/Marketplace/RequestCompletePage";
import SearchDetailPage from "../../Pages/Marketplace/SearchDetailPage";
import useScroll from "../../Components/Common/useScroll";
import NotFoundPage from "../../Pages/Marketplace/NotFoundPage";

const MarketPlaceRoutes = () => {
  const allSlugs = useSelector((state) => state.CommonReducer.allSlugs);
  const isScrolled = useScroll();

  return (
    <div className="main-layout position-relative">
      <Header />
      <div className={`inner-layout ${isScrolled ? 'scrolled' : ''}`}>
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/blogs" element={<BlogPage />} />
          <Route path="/product" element={<ProductPage/>} />
          <Route path="/sitemap" element={<SitemapPage />} />
          <Route path="/category" element={<CategoryPage />} />
          <Route path="/brand" element={<BrandPage />} />
          <Route path="/solution" element={<SolutionPage />} />
          <Route path="/realtimepricing" element={<RealTimePricePage />} />
          <Route path="/contactus" element={<ContactUsPage />} />
          <Route path="/cart" element={<CartDetailPage />} />
          <Route path="/brand-detail" element={<BrandDetailPage />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/become-a-supplier" element={<BecomeASupplier />} />
          <Route path="/faqs" element={<FaqsPage/>} />
          <Route path="/careers" element={<CareerPage />} />
          <Route path="/get-a-textile-quote" element={<GetATextileQuote />} />
          <Route path="/get-a-polymer-quote" element={<GetAPolymerQuote />} />
          <Route path="/get-a-construction-quote" element={<GetAConstructionQuote />} />
          <Route path="/get-a-finished-goods-quote" element={<GetAFinishedGoodsQuote />} />
          <Route path="/zaraye-get-a-quote" element={<GetAQuote />} />
          <Route path="/company" element={<CompanyPage />} />
          <Route path="/request-complete" element={<RequestCompletePage />} />
          <Route path="/search" element={<SearchDetailPage />} />
          {allSlugs?.map((item, index) => {
            let component;
            switch (item.Type) {
              case "Industry":
                component = <IndustryDetailPage item={item} />;
                break;
              case "Category":
                component = <CategoryDetailPage item={item} />;
                break;
              case "Product":
                component = <ProductDetailPage item={item} />;
                break;
              case "Brand":
                component = <BrandDetailPage item={item} />;
                break;
              case "Blog":
                component = <BlogDetailPage item={item} />;
                break;
              case "Topic":
                component = <TopicDetailPage item={item} />;
                break;
              default:
                component = null;
                break;
            }
            return (
              component && (
                <Route key={`routes-${index}`} path={`/${item.Slug}`} element={component} />
              )
            );
          })}
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default MarketPlaceRoutes;

import ShopingCartService from '../../Services/ShopingCart/ShopingCartService';
import * as ActionTypes from '../ActionTypes';


export const fetchShoppingCartItems = () => {
    return async (dispatch, getState) => {
        var response = await ShopingCartService.GetshoppingCartItem();
        if (response.success) {
            dispatch({
                type: ActionTypes.LOAD_SHOPPING_CART_ITEMS,
                data: response.data
            });
        } else {
            dispatch({
                type: ActionTypes.LOAD_SHOPPING_CART_ITEMS,
                data: []
            });
        }
    }
}
import React, { useState, useRef } from "react";
import myclip from "../../../Assests/Images/Global/global-video.mp4";
import "./index.css";

const Video = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isButtonHovered, setIsButtonHovered] = useState(false);
  // const [progress, setProgress] = useState(0);
  const videoRef = useRef(null);

  const togglePlay = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // const handleProgress = () => {
    // const duration = videoRef.current.duration;
    // const currentTime = videoRef.current.currentTime;
    // const progressDuration = (currentTime / duration) * 100;
    // setProgress(progressDuration);
  // };
  return (
    <div
      className={`vid-container ${isHovered ? "hovered" : ""}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <video
        // onTimeUpdate={handleProgress}
        ref={videoRef}
        width="100%"
        height="650px"
        // controls
      >
        <source src={myclip} type="video/mp4" />
      </video>
      <div className={`vid-control ${isButtonHovered ? "hovered" : ""}`}>
        <button
          className={`play-pause-button ${isPlaying ? "pauseBtn" : "playBtn"}`}
          onClick={togglePlay}
          onMouseEnter={() => setIsButtonHovered(true)}
          onMouseLeave={() => setIsButtonHovered(false)}
        >
          {/* {isPlaying ? "Pause" : "Play"} */}
        </button>
        {/* <progress value={progress} max="100" /> */}
      </div>
    </div>
  );
};

export default Video;

import React, { useState, useEffect } from "react";

const InputWithLabel = ({ ...props }) => {

    const { label = "", type = "text", value = "", onChange } = props;

    const [text, setText] = useState(value);

    const handleOnTextChage = (text) => {
        if (onChange) {
            onChange(text);
        }
    }

    useEffect(() => {
        const onLoad = () => {
            setText(value);
        }
        onLoad();
    }, [value])

    return (
        <div>
            <div className="form-item">
                <input
                    type={type}
                    //value={text}
                    autoComplete="off"
                    onChange={(e) => handleOnTextChage(e.target.value)}
                />
                <label htmlFor="username">{label}</label>
            </div>
        </div>
    )
}

export default InputWithLabel

import React, { useContext } from "react";
import HeadingDescription from "../../Common/SectionHeadingDescription";
import AhsanAliKhan from "../../../Assests/Images/Zaraye/Ahsan_Ali_Khan.png";
import TahaTeli from "../../../Assests/Images/Zaraye/Taha_Teli.png";
import "./index.css";
import LocalizationContext from "../../../Utils/LocalizationContext";

const CoFounderMessage = () => {
  const {t} = useContext(LocalizationContext)
  return (
    <div className="container">
      <div className="row text-center">
        <div className="col-sm-10 col-md-8 col-lg-8 col-xl-7 mx-auto">
          <HeadingDescription
            headingText={t('zaraye.marketplace.insightsfromourvisionaries.text')}
          />
        </div>
        <div className="col-sm-9 col-md-10 col-lg-10 col-xl-8 mx-auto">
          <HeadingDescription
            descriptionOne={t('zaraye.marketplace.insightsfromourvisionaries.description')}
          />
        </div>
      </div>
      <div className="row msg-row align-items-md-start align-items-lg-center justify-content-between">
        <div className="col-md-4 text-start">
          <img src={AhsanAliKhan} alt={AhsanAliKhan} className="w-100" loading="lazy" />
        </div>
        <div className="col-md-7 text-center text-md-start co-founders">
          <HeadingDescription
            headingText={t("zaraye.marketplace.ahsanalikhan.text")}
            subHeadingText={t("zaraye.marketplace.cofounder.title")}
            descriptionOne={t('zaraye.marketplace.cofounderahsan.description1')}
            descriptionTwo={t('zaraye.marketplace.cofounderahsan.description2')}
          />
        </div>
      </div>
      <div className="row msg-row justify-content-between align-items-md-start align-items-lg-center mt-5">
        <div className="col-md-7 text-center text-md-start order-2 order-md-1 co-founders">
          <HeadingDescription
            headingText={t("zaraye.marketplace.tahaiqbalteli.text")}
            subHeadingText={t("zaraye.marketplace.cofounder.title")}
            descriptionOne={t('zaraye.marketplace.cofoundertaha.description1')}
            descriptionTwo={t('zaraye.marketplace.cofoundertaha.description2')}
          />
        </div>
        <div className="col-md-4 text-end order-1 order-md-2">
          <img src={TahaTeli} alt={TahaTeli} className="w-100" loading="lazy" />
        </div>
      </div>
    </div>
  );
};

export default CoFounderMessage;

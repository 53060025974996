import React, { useContext } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import CustomButton from "../../Common/CustomBtn";
import { useNavigate } from 'react-router-dom';
import LocalizationContext from "../../../Utils/LocalizationContext";
import "./style.css";

const BrandSlider = ({ data = [], settings }) => {
  const {t} = useContext(LocalizationContext)
  const navigate = useNavigate();

  return (
    <div className="">
      <div className="brand-slider">
        <Slider {...settings}>
          {data?.map((item, index) => (
            <Link key={`brand-item-${index}`} to={`/${item?.SeName}`}>
              <div className={`brand-item`}>
                <img src={item?.Picture} alt={item?.BrandName} loading="lazy" />
              </div>
            </Link>
          ))}
        </Slider>
      </div>
      <div className="all-brands-resp">
        <CustomButton text={t('zaraye.marketplace.viewallbrands.text')} className='primary-btn' onClick={() => navigate('/brand')} />
      </div>
    </div>
  );
};

BrandSlider.defaultProps = {
  brands: [],
};

export default BrandSlider;

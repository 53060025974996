import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GlobalRoutes from "../../../Routes/Global/GlobalRoutes";
import MarketplaceRoutes from "../../../Routes/Marketplace/MarketplaceRoutes";
import ZarayeRoutes from "../../../Routes/Zaraye/ZarayeRoutes";
import Loader from "../../../Components/Common/Loader";
import * as commonAction from "../../../Actions/Common/CommonAction";
import * as shoppingCartAction from "../../../Actions/ShoppingCart/ShoppingCartAction";
import * as marketplaceAction from "../../../Actions/Marketplace/MarketplaceAction";
import i18n from "i18n-js";
import LocalizationContext from "../../../Utils/LocalizationContext";
import LocalStorage from "../../../Utils/LocalStorage";
import CommonService from "../../../Services/Common/CommonService";
import ScrollToTop from "../../../Components/Common/ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoadingPage = () => {
  i18n.fallbacks = true;
  const dispatch = useDispatch();

  const [langaugeLoading, setLangaugeLoading] = useState(true);
  const isLoading = useSelector((state) => state.CommonReducer.loading);
  const languageId = useSelector((state) => state.CommonReducer.languageId);
  const [locale, setLocale] = useState(languageId);
  const [loading, setLoading] = useState(true);
  const [Language, setlanguage] = useState(0);

  // useEffect(() => {
  //     handleNotificationPermission()
  //     handleLocationPermission()
  // }, [])

  const handleNotificationPermission = () => {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        } else if (permission === "denied") {
          console.log("Notification permission denied.");
        } else {
          console.log("Notification permission ignored.");
        }
      });
    } else {
      console.log("Notifications not supported in this browser.");
    }
  };

  const handleLocationPermission = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const getCountry = Intl.DateTimeFormat().resolvedOptions().timeZone;
          try {
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`
            );
            const data = await response.json();
            const countryCode = data.address.country_code;
          } catch (error) {
            console.error("Error fetching location data:", error);
          }
          console.log("Location permission granted:", position);
        },
        (error) => {
          console.error("Location permission denied:", error);
        }
      );
    } else {
      console.log("Geolocation not supported in this browser.");
    }
  };

  const localizationContext = useMemo(
    () => ({
      t: (key, config) =>
        i18n
          .t(key.replace(/\./g, ""), {
            locale: locale === undefined ? "en" : locale + "-",
            ...config,
          })
          .includes("missing")
          ? key
          : i18n.t(key.replace(/\./g, ""), {
              locale: locale === undefined ? "en" : locale + "-",
              ...config,
            }),
      locale,
      setLocale,
    }),
    [locale, setLocale]
  );

  useEffect(() => {
    const getlocal = async () => {
      var id = await LocalStorage.GetData("LanguageId");
      if (id === null || id === "" || id === undefined) {
        id = "1";
      }

      var responseLanguage = await CommonService?.GetAllLanguages();
      if (responseLanguage && responseLanguage?.success) {
        var selectedLanguage = responseLanguage?.data?.find(
          (x) => x?.Id.toString() === id.toString()
        );

        if (selectedLanguage?.UniqueSeoCode) {
          dispatch(commonAction.saveLanguage(selectedLanguage));
        }

        dispatch(commonAction.saveLanguageId(id));
        if (selectedLanguage?.Rtl) {
          document.documentElement.setAttribute("dir", "rtl");
        } else {
          document.documentElement.setAttribute("dir", "ltr");
        }

        var response = await CommonService.GetAllLocalizations(id);
        if (response && response.success) {
          var localization = response.data,
            localizationStings = "";
          for (var key in localization) {
            localizationStings +=
              '"' + key.replace(/\./g, "") + '" : "' + localization[key] + '",';
          }
          localizationStings = JSON.parse(
            "{ " + localizationStings.slice(0, -1) + " }"
          );
          i18n.translations = {};
          i18n.translations[`${selectedLanguage?.UniqueSeoCode}`] =
            localizationStings;
          i18n.locale = `${selectedLanguage?.UniqueSeoCode}`;
          i18n.defaultLocale = `${selectedLanguage?.UniqueSeoCode}`;
          setLocale(`${selectedLanguage?.UniqueSeoCode}`);
        }
      }
      setLangaugeLoading(false);
    };
    getlocal();
  }, []);

  useEffect(() => {
    const socket = new WebSocket(`wss://${"dev-api.zaraye.co"}/ws`);
    socket.onopen = () => {
      console.log("WS open");
    };
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data?.type === "RateAdd") {
        dispatch(commonAction.fetchAllRates());
      }
    };
    socket.onerror = (error) => {};
    socket.onclose = () => {
      console.log("WS close");
    };
    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (languageId > 0) {
      if (Language != languageId) {
        dispatch(commonAction.fetchIndustries());
        dispatch(commonAction.fetchLanguages());
        dispatch(commonAction.fetchAllSlug());
        dispatch(commonAction.fetchIndustriesAndCategories());
        dispatch(commonAction.fetchAllBrands());
        dispatch(commonAction.fetchAllCategories());
        dispatch(commonAction.fetchLatestBlog());
        dispatch(commonAction.fetchAllTopCategories());
        dispatch(commonAction.fetchAllWebSlider());
        dispatch(commonAction.fetchAllProductFilters());
        dispatch(commonAction.fetchProducts());
        dispatch(commonAction.fetchCountry());
        dispatch(commonAction.fetchAllBlogs());
        dispatch(commonAction.fetchAllRates());
        dispatch(commonAction.fetchAllFaqs());
        dispatch(commonAction.fetchCommodityData());
        dispatch(commonAction.fetchBestSellingProducts());
        dispatch(marketplaceAction.fetchCustomerTestimonialItems());
        dispatch(marketplaceAction.fetchEmployeeInsights());
        dispatch(marketplaceAction.fetchMarketplaceExchangerate());
        dispatch(shoppingCartAction.fetchShoppingCartItems());
        setlanguage(languageId);
      }
    }
  }, [languageId]);

  return (
    <LocalizationContext.Provider value={localizationContext}>
      <div className="position-relative">
        {isLoading && <Loader />}
        {!langaugeLoading && (
          <Router>
            <ScrollToTop />
            <Routes>
              <Route path="/*" element={<MarketplaceRoutes />} />
              <Route path="/global/*" element={<GlobalRoutes />} />
              <Route path="/zaraye/*" element={<ZarayeRoutes />} />
            </Routes>
          </Router>
        )}

        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </LocalizationContext.Provider>
  );
};

export default LoadingPage;

import { Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import CommonService from "../../../Services/Common/CommonService";
import ratingStars from "../../../Assests/Images/MarketPlace/stars.svg";
import inStock from "../../../Assests/Images/MarketPlace/inStock.svg";
import increaseQty from "../../../Assests/Images/Common/increase.svg";
import decreaseQty from "../../../Assests/Images/Common/decrease.svg";
import CustomButton from "../../../Components/Common/CustomBtn";
import { Link } from "react-router-dom";
import SelectDropdown from "../../../Components/Common/selectDropdown";
import Tabs from "../../../Components/Common/Tabs";
import OurProducts from "../../../Components/Common/Products";
import GetZarayeApp from "../../../Components/Common/getZarayeApp";
import { useLocation } from "react-router-dom";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import ShopingCartService from "../../../Services/ShopingCart/ShopingCartService";
import ProductAttributes from "../../../Components/Common/ProductAttributes";
import { AttributeControlType } from "../../../Utils/Constant";
import * as shoppingCartAction from "../../../Actions/ShoppingCart/ShoppingCartAction";
import { toast } from "react-toastify";
import { useContext } from "react";
import LocalizationContext from "../../../Utils/LocalizationContext";
import RequestAQuote from "../../../Components/Common/RequestModal";
import "./index.css";

const ProductDetailPage = ({ item }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const languageId = useSelector((state) => state.CommonReducer?.languageId);
  const { t } = useContext(LocalizationContext);

  const [productData, setProductData] = useState({});
  const [productAttributesList, setProductAttributesList] = useState([]);
  const [productAttributesCondition, setProductAttributesCondition] = useState({});
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [recentlyViewProducts, setRecentlyViewProducts] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const getProductDetail = async (id) => {
    var response = await CommonService.GetProductById(id);
    if (response?.success) {
      setProductData(response?.data);
    }
  };

  const getRelatedProducts = async (id) => {
    var response = await CommonService.GetRelatedProductsById(id);
    if (response?.success) {
      setRelatedProducts(response?.data);
    }
  };

  const getRecentlyViewProducts = async (id) => {
    var response = await CommonService.GetRecentlyViewedProducts(id);
    if (response?.success) {
      setRecentlyViewProducts(response?.data);
    }
  };

  const getProductAttributes = async (id) => {
    var response = await CommonService.GetProductAttributesById(
      id,
      location?.state?.ProductAttributesXML
    );
    if (response?.success) {
      setProductAttributesList(response?.data);
      await GetProductAttributesConditions(id);
    }
  };

  const GetProductAttributesConditions = async (id) => {
    let attributes = validateAttributes();
    var response = await CommonService.GetProductAttributeConditions(
      id,
      attributes.data
    );
    if (response.success) {
      setProductAttributesCondition(response.data);
    }
  };

  const getBrands = async (id) => {
    var response = await CommonService.GetBrandsByProductId(id);
    if (response?.success) {
      setBrandsData(response?.data);
      const tempData = response?.data?.find(
        (x) => x?.Value == location?.state?.BrandId
      );
      if (tempData) {
        setSelectedBrand(tempData);
      }
    }
  };

  useEffect(() => {
    setSelectedBrand({});
    setQuantity(1);
    setProductAttributesList([]);
    getProductDetail(item?.Id);
    getRelatedProducts(item?.Id);
    getRecentlyViewProducts(item?.Id);
    getProductAttributes(item?.Id);
    getBrands(item?.Id);
  }, [item?.Id, languageId]);

  const handleIncrement = () => {
    if (quantity < 99999) {
      setQuantity(quantity + 1);
    }
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value);
    if (!isNaN(newValue) && newValue >= 1 && newValue <= 99999) {
      setQuantity(newValue);
    }
  };

  const handleOnAttributeChange = async (data) => {
    setProductAttributesList(data);
    await GetProductAttributesConditions(item?.Id);
  };

  const handleOnChangeBrand = (value) => {
    setSelectedBrand(value);
  };

  const handleOnClickAddToCart = async () => {
    let attributes = validateAttributes();
    if (!attributes?.success) {
      toast.error(attributes?.message);
    } else if (!selectedBrand?.Value) {
      toast.error(t("zaraye.marketplace.pleaseselectbrand.error"));
    } else {
      var payload = {
        productId: item?.Id,
        brandId: selectedBrand?.Value,
        quantity: quantity,
        attributesData: attributes?.data,
      };
      var result = await ShopingCartService.AddItemToCart(payload);
      if (result.success) {
        setProductAttributesList([]);
        dispatch(shoppingCartAction.fetchShoppingCartItems());
        getProductAttributes(item?.Id);
        setQuantity(1);
      } else {
        alert(result.message);
      }
    }
  };

  const validateAttributes = () => {
    let error = [],
      data = [];
    const itemList = productAttributesList.filter(
      (x) =>
        !productAttributesCondition?.disabledattributemappingids?.includes(x.Id)
    );
    for (let index = 0; index < itemList.length; index++) {
      const item = itemList[index];
      if (item.IsRequired) {
        if (item.AttributeControlType === AttributeControlType.TextBox) {
          if (item.DefaultValue === null || item.DefaultValue === "") {
            error.push(`Please Enter ${item.Name}`);
          } else {
            data.push({
              name: `product_attribute_${item.Id}`,
              value: item.DefaultValue,
            });
          }
        } else if (
          item.AttributeControlType === AttributeControlType.NumericTextBox
        ) {
          if (item.DefaultValue === null || item.DefaultValue === "") {
            error.push(`Please Enter ${item.Name}`);
          } else {
            data.push({
              name: `product_attribute_${item.Id}`,
              value: item.DefaultValue,
            });
          }
        } else if (
          item.AttributeControlType === AttributeControlType.Dropdown
        ) {
          let length = item.Values.filter(
            (x) => x.IsPreSelected === true
          ).length;
          if (length === 0) {
            error.push(`Please Select ${item.Name}`);
          }
          item.Values.map((i) => {
            if (i.IsPreSelected) {
              data.push({
                name: `product_attribute_${item.Id}`,
                value: i.Id,
              });
            }
          });
        } else if (
          item.AttributeControlType === AttributeControlType.RadioButton
        ) {
          let length = item.Values.filter(
            (x) => x.IsPreSelected === true
          ).length;
          if (length === 0) {
            error.push(`Please Select ${item.Name}`);
          }
          item.Values.map((i) => {
            if (i.IsPreSelected) {
              data.push({
                name: `product_attribute_${item.Id}`,
                value: i.Id,
              });
            }
          });
        } else if (
          item.AttributeControlType === AttributeControlType.CheckBoxes
        ) {
          let length = item.Values.filter(
            (x) => x.IsPreSelected === true
          ).length;
          if (length === 0) {
            error.push(`Please Select ${item.Name}`);
          }
          item.Values.map((i) => {
            if (i.IsPreSelected) {
              data.push({
                name: `product_attribute_${item.Id}`,
                value: i.Id,
              });
            }
          });
        }
      } else {
        if (
          item.AttributeControlType === AttributeControlType.Dropdown ||
          item.AttributeControlType === AttributeControlType.RadioButton ||
          item.AttributeControlType === AttributeControlType.CheckBoxes
        ) {
          item.Values.map((i) => {
            if (i.IsPreSelected) {
              data.push({
                name: `product_attribute_${item.Id}`,
                value: i.Id,
              });
            }
          });
        }
      }
    }
    return { success: error.length === 0, message: error[0], data: data };
  };

  return (
    <div className="prod-detail-pg">
      <Container>
        <Helmet defer={false}>
          <meta charSet="utf-8" />
          <meta
            data-react-helmet="true"
            name="description"
            content={item?.MetaDescription ? item?.MetaDescription : ""}
          />
          <meta
            data-react-helmet="true"
            name="keywords"
            content={item?.MetaKeywords ? item?.MetaKeywords : ""}
          />
          <title>
            {item?.MetaTitle
              ? item?.MetaTitle
              : t("zaraye.marketplace.zarayeb2brawmaterialplatform.metatitle")}
          </title>
          {item?.Slug && (
            <link
              rel="canonical"
              href={`https://www.react-app.zaraye.co/${item?.Slug}`}
            />
          )}
        </Helmet>
        <div className="breadcrumb">
          <span className="pe-1 unactive">
            <Link to={"/"}>{t("zaraye.marketplace.home.breadcrumbs")}</Link>
          </span>
          <span className="pe-1 greater">
            <img src={ArrowLeft} width={14} alt="greater than" loading="lazy" />
          </span>
          <span className="pe-1 unactive">
            <Link to={"/product"}>
              {t("zaraye.marketplace.products.breadcrumbs")}
            </Link>
          </span>
          <span className="pe-1 greater">
            <img src={ArrowLeft} width={14} alt="greater than" loading="lazy" />
          </span>
          <span className="pe-1 unactive">
            {productData?.Categories?.map((item, index) => (
              <Link key={`productdata-categories-${index}`} to={`/${item?.categorySeName}`}>
                {item.CategoryName}
              </Link>
            ))}
          </span>
          <span className="pe-1 greater">
            <img src={ArrowLeft} width={14} alt="greater than" loading="lazy" />
          </span>
          <span className="activelink">{productData?.Name}</span>
        </div>
        <Row className="">
          <div className="col-lg-5 col-xl-6">
            <div className="prod-img select-prod-box-height">
              <img
                src={productData?.Picture}
                alt={productData?.Name}
                className="img-fluid prod-img-fix"
                loading="lazy"
              />
            </div>
          </div>
          <div className="col-lg-7 col-xl-6 prod-detail-right">
            <div className="pd-category">
              <Row>
                <div className="col-md-12">
                  <p>
                    {t("zaraye.marketplace.category.text")}
                    <span className="pd-cat-name ms-2">
                      {productData?.Categories?.map(
                        (item, index) => item.CategoryName
                      ).join(", ")}
                    </span>
                  </p>
                  <h2 className="pd-prod-title">{productData?.Name}</h2>
                  <div className="d-flex pd-reviews-row mb-3">
                    <div className="pd-star">
                      <img src={ratingStars} alt={ratingStars} loading="lazy" />
                    </div>
                    <div className="pd-reviews px-2">
                      <p className="mb-0">
                        ({productData?.ProductReviews?.length}{" "}
                        {t("zaraye.marketplace.reviews.text")})
                      </p>
                    </div>
                    <div className="pd-ask-question d-flex">
                      <p className="mb-0">
                        {" "}
                        {t("zaraye.marketplace.askaquestion.text")}{" "}
                      </p>
                    </div>
                    <div className="in-stock d-flex align-items-center px-2">
                      <img src={inStock} alt={inStock} loading="lazy" />
                      <p
                        className={`mb-0 ${
                          document.documentElement.dir === "rtl"
                            ? "me-2"
                            : "ms-2"
                        }`}
                      >
                        {" "}
                        {t("zaraye.marketplace.instock.text")}
                      </p>
                    </div>
                  </div>
                  <div className="pd-description">
                    <p>{productData?.ShorDescription}</p>
                  </div>
                </div>
              </Row>
              <div className="prod-fields">
                <ProductAttributes
                  data={productAttributesList}
                  onAttributeChange={handleOnAttributeChange}
                  disabledattributeId={
                    productAttributesCondition?.disabledattributemappingids
                  }
                />
                <Row className="mt-2">
                  <div className="col-md-8 form-options mb-3">
                    <SelectDropdown
                      optionValueProp="Value"
                      optionLabelProp="Text"
                      data={brandsData}
                      label={"Brands"}
                      placeholder={"Select Brand"}
                      onChange={handleOnChangeBrand}
                      value={selectedBrand}
                    />
                  </div>
                </Row>
                <Row className="mt-2">
                  <div className="col-md-10 form-options">
                    <div className="d-flex form-item align-items-center">
                      {productData?.UnitName ? (
                        <p className="mb-0">
                          {t("zaraye.marketplace.quantity.text")} (
                          {productData.UnitName}):
                        </p>
                      ) : (
                        <p className="mb-0">
                          {t("zaraye.marketplace.quantity.text")}
                        </p>
                      )}
                      <div className="qtyField position-relative mx-3">
                        <input
                          className="w-100"
                          type="number"
                          value={quantity}
                          min="1"
                          max="99999"
                          onChange={handleChange}
                        />
                        <div className="decrease-qty" onClick={handleDecrement}>
                          <img
                            src={decreaseQty}
                            alt={decreaseQty}
                            loading="lazy"
                          />{" "}
                        </div>
                        <div className="increase-qty" onClick={handleIncrement}>
                          <img
                            src={increaseQty}
                            alt={increaseQty}
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Row>
                <Row className="mt-4">
                  <div className="col-md-12 d-flex align-items-center prod-detail-btn">
                    <CustomButton
                      text={t("zaraye.marketplace.addtocart.text")}
                      className={"primary-btn me-3"}
                      onClick={handleOnClickAddToCart}
                    />
                    <p className="mb-0">
                      <Link
                        className={`text-dark ${
                          document.documentElement.dir === "rtl" ? "me-2" : ""
                        }`}
                        onClick={() => {
                          setProductData(productData);
                          setModalShow(true);
                        }}
                      >
                        {t("zaraye.marketplace.callusnow.text")}
                      </Link>
                    </p>
                    <RequestAQuote
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      productimage={productData?.Picture}
                      productname={productData?.Name}
                    />
                  </div>
                </Row>
              </div>
            </div>
          </div>
        </Row>
        {productData?.LongDescription?.length > 0 ? (
          <Row className="product-description-section">
            <div className="col-md-12">
              <Tabs
                tabs={[
                  {
                    label: t("zaraye.marketplace.productdescription.text"),
                    content: productData?.LongDescription,
                  },
                  // {
                  //   label: 'Additional Information',
                  //   content: 'Content for Tab 2'
                  // }
                ]}
              />
            </div>
          </Row>
        ) : (
          ""
        )}
      </Container>
      {relatedProducts.length > 0 && (
        <div className="mp-home">
          <Container className="also-like-section position-relative">
            <OurProducts
              data={relatedProducts}
              headingText={t("zaraye.marketplace.youmayalsolike.text")}
            />
          </Container>
        </div>
      )}
      {recentlyViewProducts.length > 0 && (
        <div className="mp-home">
          <Container className="position-relative recent-view-products text-center text-md-start">
            <OurProducts
              data={recentlyViewProducts}
              headingText={t("zaraye.marketplace.recentviewproducts.text")}
              descriptionOne={t(
                "zaraye.marketplace.recentviewproducts.description"
              )}
            />
          </Container>
        </div>
      )}
        <GetZarayeApp />
    </div>
  );
};

export default ProductDetailPage;

import { Link } from 'react-router-dom';
import quteArrow from '../../../Assests/Images/Zaraye/get-quote-img.png';
import CustomButton from '../CustomBtn';

const HeroMainTitle = ({ topTagLine, title, description, btnText, linkText }) => {
    return (
        <div>
            <h1 className="welcome">{topTagLine}</h1>
            <h1 className="main-heading">{title} </h1>
            <p>{description}</p>
            <div className="explore-box">
                <div className="get-quote-img">
                    <img src={quteArrow} alt={quteArrow} loading="lazy" />
                </div>
                <CustomButton className="primary-btn" text={btnText} />
                <p className="get-customize mb-0">
                    <Link to="/">{linkText}</Link>
                </p>
            </div>
        </div>
    )
}

export default HeroMainTitle

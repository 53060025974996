import React, { useContext } from "react";
import Selling from "../../../Assests/Images/Zaraye/selling.svg";
import Guranteed from "../../../Assests/Images/Zaraye/guranteed.svg";
import Delivery from "../../../Assests/Images/Zaraye/fast-delivery.svg";
import SaveTime from "../../../Assests/Images/Zaraye/save-time.svg";
import Flexible from "../../../Assests/Images/Zaraye/flexible.svg";
import Video from "../../Common/Video";
import LocalizationContext from "../../../Utils/LocalizationContext";
import "./style.css";

const OneStopSolution = () => {
  const {t} = useContext(LocalizationContext)
  const data = [
    {
      image: Selling,
      heading: t('zaraye.marketplace.effortless.selling'),
    },
    {
      image: Guranteed,
      heading: t("zaraye.marketplace.best.ratesguaranteed.text"),
    },
    {
      image: Delivery,
      heading: t("zaraye.marketplace.fast.delivery.text"),
    },
    {
      image: SaveTime,
      heading: t("zaraye.marketplace.save.time.text"),
    },
    {
      image: Flexible,
      heading: t("zaraye.marketplace.flexible.financing"),
    },
  ];
  const sourcingDetails = [
    {
      heading: t('zaraye.marketplace.cities'),
      description:
        t('zaraye.marketplace.cities.title'),
    },
    {
      heading: t('zaraye.marketplace.no.of.industries'),
      description:
        t('zaraye.marketplace.no.of.industries.title'),
    },
    {
      heading: t('zaraye.marketplace.no.of.partners'),
      description:
        t('zaraye.marketplace.no.of.partners.title'),
    },
  ];
  return (
    <div className="explore-product-section one-stop-solution-section">
      <div className="container">
        <div className="row d-flex justify-content-center pb-4">
          <div className="col-sm-10 col-md-8 col-lg-8 col-xl-7 mx-auto home-title">
            <h1 className="main-heading text-center mb-4">{t('zaraye.marketplace.youronestopsolution.text')}</h1>
          </div>
          <div className="col-sm-9 col-md-10 col-lg-10 col-xl-6">
            <p className="text-center">{t('zaraye.marketplace.youronestopsolution.description')}</p>
          </div>
        </div>
        <div className="row">
          <div className="d-flex flex-wrap justify-content-center justify-content-md-between w-100">
            {data?.map((item, index) => (
              <div
                key={`easy-sourcing-${index}`}
                className="d-flex flex-column align-items-center solution-section"
              >
                <img src={item?.image} alt={item?.heading} loading="lazy" />
                <h5 className="mt-2">{item?.heading}</h5>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="container-fluid navigate-intricacies-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 video-column">
              <Video />
            </div>
          </div>
          <div className="row navigate-intricacies-row">
            {sourcingDetails?.map((item, index) => (
              <div key={`easy-sourcing-${index}`} className="col-md-4 col-lg-3 home-title">
                <h1 className="main-heading text-white">{item?.heading}</h1>
                <p className="text-white">{item?.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneStopSolution;

import RestClient from '../RestClient';

const AddRequest = (payload) => {
    return RestClient.Post(`/request/add-request`, payload);
}

const exportedObject = {
    AddRequest,
};

export default exportedObject;
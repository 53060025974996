import RestClient from "../RestClient";

const GetAllLocalizations = (languageId) => {
  return RestClient.Get(
    `common/all-localizations/zaraye.marketplace/${languageId}`
  );
};

const GetAllLanguages = () => {
  return RestClient.Get(`common/all-languages`);
};

const GetAllCountries = () => {
  return RestClient.Get(`common/all-countries`);
};

const GetAllStatesByCountryId = (id) => {
  return RestClient.Get(`common/all-states/${id}`);
};

const GetAllAreaByStatesId = (id) => {
  return RestClient.Get(`common/all-areas/${id}`);
};

const GetIndustriesAndCategories = () => {
  return RestClient.Get(`common/get-industries-and-categories`);
};

const GetIndustriesDetail = (id) => {
  return RestClient.Get(
    `common/get-industry-detail/${id}?pageIndex=0&pageSize=2147483647`
  );
};

const GetAllIndustries = () => {
  return RestClient.Get(`common/all-industries`);
};

const GetAllBrands = () => {
  return RestClient.Get(`common/get-all-brands`);
};

const GetAllSlugName = () => {
  return RestClient.Get(`common/all-se-name`);
};

const SetLanguage = (id) => {
  return RestClient.Post(`common/set-language/${id}`, null);
};

const GetLatestBlog = (noofblogs = 4) => {
  return RestClient.Get(`common/get-latest-blogs/${noofblogs}`);
};

const GetAllBlogs = (pageIndex, pageSize) => {
  return RestClient.Get(
    `common/get-all-blogs?pageIndex=${pageIndex}&pagesize=${pageSize}`
  );
};

const GetBlogById = (id) => {
  return RestClient.Get(`common/get-blog-detail/${id}`);
};

const GetTopicById = (id) => {
  return RestClient.Get(`common/get-topic-detail/${id}`);
};

const GetAllTopCAtegories = () => {
  return RestClient.Get(`common/get-all-top-categories`);
};

const GetAllWebSlider = () => {
  return RestClient.Get(`common/get-all-web-sliders`);
};

const GetAllProductFilters = () => {
  return RestClient.Get(`common/get-all-product-filters`);
};

const GetProducts = (payload) => {
  return RestClient.Post(`common/get-all-filtered-product`, payload);
};

const GetProductById = (id) => {
  return RestClient.Get(`common/get-product-details/${id}`);
};

const GetBrandById = (id) => {
  return RestClient.Get(`/common/get-brand-details/${id}`);
};

const GetCategoryById = (id) => {
  return RestClient.Get(`/common/get-category-details/${id}`);
};

const GetBrandsByProductId = (id) => {
  return RestClient.Get(`common/all-brands/${id}`);
};

const GetProductAttributesById = (id, attributesXml = "") => {
  return RestClient.Get(
    `common/product-attributes/${id}?attributesXml=${attributesXml}`
  );
};

const GetProductAttributeConditions = (id, data) => {
  return RestClient.Post(`common/product-attribute-change/${id}`, data, false);
};

const GetAllRates = () => {
  return RestClient.Get(`common/get-all-rates`);
};

const GetAllFaqs = () => {
  return RestClient.Get(`common/get-faqs-by-type?isApp=false`);
};

const GetAllBestSellingProducts = (pageIndex, pageSize) => {
  return RestClient.Get(
    `common/best-selling-product?pageIndex=0&pageSize=2147483647`
  );
};

const GetCommodityData = () => {
  return RestClient.Get(`common/get-commodity-data`);
};

const GetRelatedProductsById = (id) => {
  return RestClient.Get(`common/get-related-products?productId=${id}`);
};

const GetRecentlyViewedProducts = () => {
  return RestClient.Get(`common/get-recently-viewed-products`);
};

const ContactUs = (payload) => {
  return RestClient.Post(`common/contact-us`, payload);
};

const GetAllCategories = (payload) => {
  return RestClient.Post(`common/explore-all-category`, payload);
};

const exportedObject = {
  SetLanguage,
  GetAllLocalizations,
  GetAllLanguages,
  GetAllWebSlider,
  GetAllCountries,
  GetAllStatesByCountryId,
  GetAllAreaByStatesId,
  GetAllIndustries,
  GetAllSlugName,
  GetIndustriesAndCategories,
  GetAllBrands,
  GetLatestBlog,
  GetAllBlogs,
  GetBlogById,
  GetTopicById,
  GetAllTopCAtegories,
  GetAllProductFilters,
  GetProducts,
  GetProductById,
  GetBrandById,
  GetBrandsByProductId,
  GetProductAttributesById,
  GetProductAttributeConditions,
  GetCategoryById,
  GetIndustriesDetail,
  GetAllRates,
  GetAllFaqs,
  GetAllBestSellingProducts,
  GetCommodityData,
  GetRelatedProductsById,
  GetRecentlyViewedProducts,
  ContactUs,
  GetAllCategories,
};

export default exportedObject;

import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import LandingPagesMainTitle from "../../../Components/Zaraye/LandingPagesTitle";
import { useSelector } from "react-redux";
import SelectDropdown from "../../../Components/Common/selectDropdown";
import Input from "../../../Components/Common/input";
import Button from "../../../Components/Common/CustomBtn";
import LocalizationContext from "../../../Utils/LocalizationContext";
import "./style.css";

const BrandPage = ({ item }) => {
  const allBrands = useSelector((state) => state.CommonReducer.allBrands);
  const industryList = useSelector((state) => state.CommonReducer.industries);
  const [brandName, setBrandName] = useState("");
  const [filterBrands, setFilterBrands] = useState(allBrands);
  const [selectedIndustry, setSelectedIndustry] = useState({});
  const { t } = useContext(LocalizationContext);

  const handleSelectIndustry = (item) => {
    setSelectedIndustry(item);
  };

  const handleOnClickApply = async () => {
    var data = allBrands;

    if (brandName?.toLowerCase()) {
      data = data.filter((x) =>
        (x?.BrandName).toLowerCase().includes(brandName?.toLowerCase())
      );
    }

    if (selectedIndustry?.Value) {
      data = data.filter(
        (x) => parseInt(x?.IndustryId) === parseInt(selectedIndustry?.Value)
      );
    }

    setFilterBrands(data);
  };

  const quoteFormDropdownStyles = {
    menu: (provided, state) => ({
      ...provided,
      borderRadius: "8px", // Add border-radius
      border: "1px solid teal",
      marginLeft: "-10px",
      fontFamily: "Regular",
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),

    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 8px",
      fontFamily: "Light",
    }),

    container: (provided, state) => ({
      ...provided,
      border: "1px solid teal",
      borderRadius: "40px",
      padding: "0 10px",
      background: "#ffffff42",
      marginTop: "0",
      fontSize: "14px",
      fontFamily: "Light",
      color: "#000",
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      background: "none",
      minHeight: "32px",
      border: "0",
      svg: {
        height: "17px",
        width: "17px",
      },
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#808080",
      border: "0",
      padding: "0",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "teal" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: "teal",
        color: "white",
        border: "0",
      },
    }),
  };

  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <meta
          data-react-helmet="true"
          name="description"
          content={item?.MetaDescription ? item?.MetaDescription : ""}
        />
        <meta
          data-react-helmet="true"
          name="keywords"
          content={item?.MetaKeywords ? item?.MetaKeywords : ""}
        />
        <title>
          {item?.MetaTitle
            ? item?.MetaTitle
            : t("zaraye.marketplace.zarayeb2brawmaterialplatform.metatitle")}
        </title>
        {item?.Slug && (
          <link
            rel="canonical"
            href={`http://app-react.zaraye.co/${item?.Slug}`}
          />
        )}
      </Helmet>
      <section className="brands-page">
        <Container>
          <div className="breadcrumb">
            <span className="pe-1 unactive">
              <Link to={"/"}>{t("zaraye.marketplace.home.breadcrumbs")}</Link>
            </span>
            <span className="pe-1 greater">
              <img
                src={ArrowLeft}
                width={14}
                alt="greater than"
                loading="lazy"
              />
            </span>
            <span className="activelink">
              {t("zaraye.marketplace.brands.breadcrumbs")}
            </span>
          </div>
          <div className="all-brands-main">
            <LandingPagesMainTitle
              headingText={t("zaraye.marketplace.brands.main.title")}
              descriptionOne={t("zaraye.marketplace.brands.main.description")}
            />
            <Row className="brands-filters">
              <div className="col-sm-12 col-md-4 col-lg-3 mb-3 mb-md-0">
                <Input
                  className={"form-control"}
                  placeholder={t(
                    "zaraye.marketplace.enterbrandname.placeholder"
                  )}
                  type="text"
                  name={"name"}
                  onChange={(e) => {
                    setBrandName(e.target.value);
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 mb-3 mb-md-0">
                <SelectDropdown
                  value={selectedIndustry}
                  onChange={handleSelectIndustry}
                  styles={quoteFormDropdownStyles}
                  isSearchable={true}
                  isClearable={true}
                  placeholder={t(
                    "zaraye.marketplace.selectindustry.placeholder"
                  )}
                  data={industryList}
                  optionValueProp={"Value"}
                  optionLabelProp={"Text"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-3 brand-filter-btn mb-3 mb-md-0">
                <Button
                  className={"primary-btn w-100 text-center"}
                  text={t("zaraye.marketplace.applyfilter.placeholder")}
                  onClick={handleOnClickApply}
                />
              </div>
            </Row>
            <div className="d-flex flex-wrap mx-auto all-brands">
              {filterBrands?.length > 0 ? (
                filterBrands.map((item, index) => (
                  <Link
                    key={`item.brands-${index}`}
                    to={`/${item?.SeName}`}
                    className="brand-item"
                  >
                    <img
                      src={item?.Picture}
                      alt={item?.BrandName}
                      loading="lazy"
                    />
                  </Link>
                ))
              ) : (
                <p className="no-data-found">
                  {t("zaraye.marketplace.nodatafound.text")}
                </p>
              )}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default BrandPage;

import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import HeadingDescription from "../SectionHeadingDescription";
import CloseIcon from "../../../Assests/Images/Common/close-dark.svg";
import Input from "../input";
import { Link } from "react-router-dom";
import Button from "../CustomBtn";
import { Row } from "react-bootstrap";
import MobileTracking from "../../../Assests/Images/Common/mobile-tracking-screen.png";
import AppStore from "../../../Assests/Images/Common/appstore.png";
import PlayStore from "../../../Assests/Images/Common/googleplay.png";
import LocalizationContext from "../../../Utils/LocalizationContext";
import MarketPlaceService from "../../../Services/MarketPlace/MarketPlaceService";
import { toast } from "react-toastify";
import PhoneNumberInput from "../countryInput";
import "./style.css";

const RequestAQuote = (props) => {
  const { onHide, productimage, productname } = props;
  const [productName, setProductName] = useState("");
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [selectCountry, setSelectedCountry] = useState({});
  const [show, setShow] = useState(false);
  const [phoneNumberValidity, setPhoneNumberValidity] = useState(false);
  const { t } = useContext(LocalizationContext);

  const [selectCountry, setSelectedCountry] = useState({});
  const [quantity, setQuantity] = useState("");
  const [units, setUnits] = useState({});
  const [city, setCity] = useState("");

  // const handleOnClickSubmitRequest = async () => {
  //   setShow(true);
  //   onHide();
  // };

  // const handleSelectCountry = (selectCountry) => {
  //   setSelectedCountry(selectCountry);
  // };

  const handleOnClickSubmitRequest = async () => {
    if (props.productname === "") {
      toast.error(t("zaraye.marketplace.pleaseenteryourname.error"));
    } else if (name === "") {
      toast.error(t("zaraye.marketplace.pleaseenterproductname.error"));
    } else if (phoneNumber === "") {
      toast.error(t("zaraye.marketplace.pleaseenteryourphonenumber.error"));
    } else if (!phoneNumberValidity) {
      toast.error(t("zaraye.marketplace.pleaseenterphonenumber.error"));
    } else if (email === "") {
      toast.error(t("zaraye.marketplace.pleaseenteryouremail.error"));
    } else {
      const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (reg.test(email) === false) {
        toast.error(t("zaraye.marketplace.pleaseentervalidemail.error"));
      } else {
        var payload = {
          service: props.productname,
          name: name,
          contactNumber: phoneNumber,
          email: email,
          source: 'quick cart',
          quantity: 1,
          cityId: 0,
          unit: 0,
          cityName: city,
          countryId: 0,
        };
        var result = await MarketPlaceService?.AddOnlineLead(payload);
        if (result?.success) {
          setProductName("");
          setName("");
          setPhoneNumber("");
          setEmail("");
          toast.success("Lead Successfully Generated");
          setSelectedCountry("");
          setQuantity("");
          setUnits({});
          setCity("");
        }
        setShow(true);
        onHide();
      }
    }
  };

  const handleClose = () => setShow(false);

  return (
    <div className="quote-modal">
      {/* request-modal */}
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-0 main-form">
          <div className="d-flex justify-content-end modal-close">
            <img
              src={CloseIcon}
              alt="Close"
              width={16}
              className="cursor-pointer"
              onClick={onHide}
              loading="lazy"
            />
          </div>
          <Row>
            <div className="col-lg-5 modal-image pe-0 mdl-prod">
              <img src={productimage} alt={productimage} loading="lazy" className="mdl-prod-img"/>
            </div>
            <div className="col-lg-7 pt-4 pb-4">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-11 col-xl-10 col-xl-9 request-headings">
                  <HeadingDescription
                    headingText={t(
                      "zaraye.marketplace.getyourinstantquote.text"
                    )}
                    descriptionOne={t(
                      "zaraye.marketplace.getyourinstantquote.description"
                    )}
                  />
                </div>
              </div>
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-11 col-xl-10 col-xl-9">
                  <div className="form-group">
                    <Input
                      className={"form-control text-center"}
                      placeholder={productname}
                      type="text"
                      name={"productname"}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-11 col-xl-10 col-xl-9">
                  <div className="form-group">
                    <Input
                      className={"form-control"}
                      placeholder={t("zaraye.marketplace.enteryourname.placeholder")}
                      type="text"
                      value={name}
                      maxLength={50}
                      onChange={(e) => {
                        if (!e.target.value || /^[a-zA-Z\s]+$/.test(e.target.value)) {
                          setName(e.target.value);
                        }
                      }}
                      name={"name"}
                    />
                  </div>
                </div>
                <div className="col-lg-11 col-xl-10 col-xl-9">
                  <div className="form-group">
                    <PhoneNumberInput
                      phoneNumber={phoneNumber}
                      onChangePhonenumber={(value) => {
                        setPhoneNumber(value);
                      }}
                      onPhoneNumberValidityChange={(validity) => {
                        setPhoneNumberValidity(validity);
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-11 col-xl-10 col-xl-9">
                  <div className="form-group">
                    <Input
                      className={"form-control"}
                      placeholder={t("zaraye.marketplace.emailaddress.placeholder")}
                      type="email"
                      value={email}
                      maxLength={50}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      name={"Email Address"}
                    />
                  </div>
                  <Button
                    className={"primary-btn w-100 text-center ripple"}
                    text={t("zaraye.marketplace.submitrequest.text")}
                    onClick={handleOnClickSubmitRequest}
                  />
                </div>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>

      {/* thankyou-modal */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Body className="p-0 main-form">
          <div className="d-flex justify-content-end modal-close">
            <img
              src={CloseIcon}
              alt="Close"
              width={16}
              className="cursor-pointer"
              onClick={handleClose}
              loading="lazy"
            />
          </div>
          <Row className="justify-content-center">
            <div className="col-md-10 pt-4 pb-4 d-flex flex-column">
              <div className="row align-items-center justify-content-center pb-3">
                <div className="col-md-11 request-headings">
                  <HeadingDescription
                    headingText={t("zaraye.marketplace.requestedcomplete.text")}
                    descriptionOne={t(
                      "zaraye.marketplace.requestedcomplete.description"
                    )}
                  />
                </div>
              </div>
              <img src={MobileTracking} alt={MobileTracking} loading="lazy" />
              <div className="d-flex mt-3 resp-apps-icons">
                <Link
                  target="_blank"
                  to={
                    "https://play.google.com/store/apps/details?id=com.zaraye.app"
                  }
                >
                  <img
                    src={PlayStore}
                    alt={PlayStore}
                    loading="lazy"
                    width={182}
                    className="mr-3"
                  />
                </Link>
                <Link
                  target="_blank"
                  to={"https://apps.apple.com/app/id1608301344"}
                >
                  <img
                    src={AppStore}
                    alt={AppStore}
                    loading="lazy"
                    width={182}
                    className="ms-3"
                  />
                </Link>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RequestAQuote;

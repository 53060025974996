import React, { useState, useEffect } from "react";
import Select from "react-select";

const isRtl = document.documentElement.getAttribute("dir") === "rtl";

const SelectDropdown = ({ ...props }) => {
  const {
    data = [],
    label = "",
    optionValueProp = "value",
    optionLabelProp = "label",
    onChange,
    styles,
    placeholder = "",
    value,
    isSearchable = false,
    isClearable = false,
  } = props;

  const [options, setOptions] = useState(data);

  const handleOnChange = (item) => {
    if (onChange) {
      var temp = {};
      temp[optionValueProp] = item ? item.value : "";
      temp[optionLabelProp] = item ? item.label : "";
      onChange(temp);
    }
  };

  useEffect(() => {
    const onLoad = () => {
      var dropDownOptions = [];
      data?.map((item, index) => {
        dropDownOptions.push({
          value: item[optionValueProp],
          label: item[optionLabelProp],
        });
      });
      setOptions(dropDownOptions);
    };
    if (data.length > 0) {
      onLoad();
    }
  }, [data]);

  const dropdownWithLabel = {
    menu: (provided, state) => ({
      ...provided,
      borderRadius: "8px", 
      border: "1px solid rgb(15 138 140 / 50%)",
      zIndex: 99,
      marginLeft: isRtl ? "0" : "-10px",
      marginRight: isRtl ? "-10px" : "0",
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),

    valueContainer: (provided) => ({
      ...provided,
      padding: "4px 8px",
    }),

    container: (provided, state) => ({
      ...provided,
      border: "1px solid rgb(15 138 140 / 50%)",
      borderRadius: "40px",
      padding: "0 10px",
      background: "#ffffff42",
      marginTop: "10px",
      fontSize: "12px",
      fontFamily: "Light",
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      background: "none",
      minHeight: "42px",
      border: "0",
      height: "50px",

      svg: {
        height: "17px",
        width: "17px",
      },
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#808080",
      border: "0",
      padding: "0",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "teal" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: "teal",
        color: "white",
        // border: "2px solid",
      },
    }),
  };

  return label !== "" ? (
    <div className="form-group position-relative prod-select">
      <Select
        options={options}
        onChange={handleOnChange}
        styles={dropdownWithLabel}
        placeholder={placeholder}
        // menuIsOpen={true}
        value={
          value !== undefined && value[optionLabelProp] !== undefined
            ? {
              value: value[optionValueProp],
              label: value[optionLabelProp],
            }
            : ""
        }
        isSearchable={isSearchable}
        isClearable={isClearable}
      />
      <label>{label}</label>
    </div>
  ) : (
    <Select
      isSearchable={isSearchable}
      isClearable={isClearable}
      options={options}
      // menuIsOpen={true}
      onChange={handleOnChange}
      styles={styles}
      placeholder={placeholder}
      Value={
        value && value[optionLabelProp] !== undefined
          ? {
            value: value[optionValueProp],
            label: value[optionLabelProp],
          }
          : ""
      }
    />
  );
};

export default SelectDropdown;

import React from "react";
import { Link } from "react-router-dom";
import Prev from "../../../Assests/Images/Common/page-prev.svg";
import Next from "../../../Assests/Images/Common/page-next.svg";
import "./index.css";

const Pagination = ({
  totalPages,
  currentPage,
  onPageChange,
  HasPreviousPage,
  HasNextPage,
}) => {
  const goToFirstPage = () => {
    onPageChange(0);
  };

  const goToLastPage = () => {
    onPageChange(totalPages);
  };
  return (
    <div className="d-flex w-100 justify-content-end">
      <ul className="pagination">
        <li className={`show-prev ${HasNextPage ? "show" : "hide"}`}>
          <img src={Prev} alt={Prev} loading="lazy" onClick={goToFirstPage} />
          <Link
            onClick={() =>
              onPageChange(currentPage - 1 > 0 ? currentPage - 1 : 1)
            }
          >
            Prev
          </Link>
        </li>
        {[...Array(totalPages).keys()].map((item) => (
          <li
            key={`item ${item}`}
            className={
              item === currentPage ? "active fw-bold" : "px-1"
            }
          >
            <Link onClick={() => onPageChange(item)}>{item + 1}</Link>
          </li>
        ))}
        <li className={`show-next ${HasNextPage ? "show" : "hide"}`}>
          <Link
            onClick={() =>
              onPageChange(
                currentPage + 1 <= totalPages ? currentPage + 1 : totalPages
              )
            }
          >
            Next
          </Link>
          <img src={Next} alt={Next} loading="lazy" onClick={goToLastPage} />
        </li>
      </ul>
    </div>
  );
};

export default Pagination;

import { Container, Row } from "react-bootstrap";

const NotFoundPage = () => {
    return (
      <Container>
            <Row className="text-center not-found">
                <h1>404 Not Found</h1>
                <p>Sorry, the page you are looking for does not exist.</p>
            </Row>
      </Container>
    );
};

export default NotFoundPage;

import React, { useEffect, useState, useContext } from "react";
import { Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import LocalizationContext from "../../../Utils/LocalizationContext";
import MarketPlaceService from "../../../Services/MarketPlace/MarketPlaceService";
import "./style.css";

const SearchDetailPage = () => {
  const [searchData, setSearchData] = useState([]);
  const { t } = useContext(LocalizationContext);
  const location = useLocation();

  const getSearchDetail = async (keyword) => {
    var response = await MarketPlaceService.GetMarketPlaceSearch(keyword);
    if (response?.success) {
      setSearchData(response?.data);
    }
  };

  useEffect(() => {
    getSearchDetail(location?.state?.searchTerm);
  }, [location?.state?.searchTerm]);

  return (
    <div className="search-pg">
      <Container className="thankyou-page">
        <div className="breadcrumb">
          <span className="pe-1 unactive">
            <Link to={"/"}>{t("zaraye.marketplace.home.breadcrumbs")}</Link>
          </span>
          <span className="pe-1 greater">
            <img src={ArrowLeft} width={14} alt="greater than" loading="lazy" />
          </span>
          <span className="activelink">{t('zaraye.marketplace.searchdetail.breadcrumbs')}</span>
        </div>
      </Container>
      <Container>
        <Row>
          <div className="col-md-12">
            <h6 className="fw-bold text-dark search-counts">
              <span>{searchData?.length}</span>{" "}
              {t("zaraye.marketplace.itemsfoundfor.text")}{" "}
              <span>"{location?.state?.searchTerm}"</span>
            </h6>
          </div>
        </Row>
        <Row>
          <div
            className={
              searchData?.filter((x) => x.Type === "Topic" || x.Type === "Blog")
                .length <= 0
                ? "col-xl-12"
                : "col-xl-8"
            }
          >
            {/* <div key={`blog-item-${index}`} className={`${index < 2 ? "col-md-6" : "col-md-4"} mb-4 mt-4`} > */}
            {searchData?.filter((x) => x.Type === "Product").length !== 0 && (
              <div>
                {searchData
                  ?.filter((x) => x.Type === "Product")
                  ?.map((item, index) => (
                    <div key={`search-data-product-${index}`} className="search-product-list">
                      <Link
                        to={`/${item?.SeName}`}
                        className="d-flex justify-content-between align-items-center text-decoration-none"
                      >
                        <div className="spl-img">
                          <img src={item?.Picture} alt={item?.Title} loading="lazy" />
                        </div>
                        <div className="spl-content">
                          <div className="spl-content-category">
                            <p className="mb-1 spl-cat-name">
                              {t(
                                "zaraye.marketplace.searchdetail.category.text"
                              )}
                              <span className="fw-bold"> {item?.Type}</span>
                            </p>
                            <h4>{item?.Title}</h4>
                            <p className="mb-0 spl-content-detail">
                              {item?.BodyOverView}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
              </div>
            )}
            {/* related categories */}
            {searchData?.filter((x) => x.Type === "Category").length !== 0 && (
              <Row>
                <div className="col-md-12 searh-product- popular-products-section mt-0">
                  <div className="mb-4">
                    <h2>{t("zaraye.marketplace.relatedcategories.text")}</h2>
                    <p>{t("zaraye.marketplace.relatedindustry.description")}</p>
                  </div>
                  <div className="col-md-12 d-flex flex-wrap grid-product position-relative">
                    {searchData
                      ?.filter((x) => x.Type === "Category")
                      ?.map((item, index) => (
                        <div key={`search-data-category-${index}`} className="card products p-1">
                          <Link
                            to={`/${item?.SeName}`}
                            className="text-decoration-none inner-products"
                          >
                            <div className="position-relative prod-box-height cat-thumbnail">
                              <img
                                src={item?.Picture}
                                alt={item?.Title}
                                className="w-100 cat-img-fix"
                                loading="lazy"
                              />
                            </div>
                            <h5 className="mb-0 mt-3">{item?.Title}</h5>
                            <h6 className="mt-2">
                              {t(
                                "zaraye.marketplace.explorecategories.linktext"
                              )}
                            </h6>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
              </Row>
            )}
            {/* related Industry */}
            {searchData?.filter((x) => x.Type === "Industry").length !== 0 && (
              <Row className="searh-product- popular-products-section mt-0">
                <div className="col-md-12 mb-4">
                  <h2>{t("zaraye.marketplace.relatedindustry.text")}</h2>
                  <p>{t("zaraye.marketplace.relatedindustry.description")}</p>
                </div>
                <div className="col-md-12 d-flex flex-wrap grid-product position-relative">
                  {searchData
                    ?.filter((x) => x.Type === "Industry")
                    ?.map((item, index) => (
                      <div key={`search-data-industry-${index}`} className="card products p-1">
                        <Link
                          to={`/${item?.SeName}`}
                          className="text-decoration-none inner-products"
                        >
                          <div className="position-relative prod-box-height cat-thumbnail">
                            <img
                              src={item?.Picture}
                              alt={item?.Title}
                              className="w-100 cat-img-fix"
                              loading="lazy"
                            />
                          </div>
                          <h5 className="mb-0 mt-3">{item?.Title}</h5>
                          <h6 className="mt-2">
                            {t("zaraye.marketplace.exploreindustry.linktext")}
                          </h6>
                        </Link>
                      </div>
                    ))}
                </div>
              </Row>
            )}
            {/* related brand */}
            {searchData?.filter((x) => x.Type === "Brand").length !== 0 && (
              <Row className="searh-product- popular-products-section mt-0">
                <div className="col-md-12 mb-4">
                  <h2>{t("zaraye.marketplace.relatedbrand.text")}</h2>
                  <p>{t("zaraye.marketplace.relatedindustry.description")}</p>
                </div>
                <div className="col-md-12 d-flex flex-wrap grid-product position-relative">
                  {searchData
                    ?.filter((x) => x.Type === "Brand")
                    ?.map((item, index) => (
                      <div key={`search-data-brand-${index}`} className="card products p-1">
                        <Link
                          to={`/${item?.SeName}`}
                          className="text-decoration-none inner-products"
                        >
                          <div className="position-relative prod-box-height cat-thumbnail">
                            <img
                              src={item?.Picture}
                              alt={item?.Title}
                              className="w-100 cat-img-fix"
                              loading="lazy"
                            />
                          </div>
                          <h5 className="mb-0 mt-3">{item?.Title}</h5>
                          <h6 className="mt-2">
                            {t("zaraye.marketplace.explorebrand.linktext")}
                          </h6>
                        </Link>
                      </div>
                    ))}
                </div>
              </Row>
            )}
          </div>
          {/* Related Articles */}
          {searchData?.filter((x) => x.Type === "Topic" || x.Type === "Blog")
            .length !== 0 && (
            <div className="col-md-12 col-lg-4">
              <div className="spa-title">
                <h2>{t("zaraye.marketplace.relatedarticles.text")}</h2>
              </div>
              <div className="col-md-12">
                <div className="search-product-articles">
                  {searchData
                    ?.filter((x) => x.Type === "Topic" || x.Type === "Blog")
                    ?.map((item, index) => (
                      <div key={`search-data-${index}`} className="spa-list">
                        <Link
                          to={`/${item?.SeName}`}
                          className="text-decoration-none"
                        >
                          <h6>{item?.Title}</h6>
                          <p>{item?.BodyOverView}</p>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default SearchDetailPage;

import React, { useContext } from "react";
import { Container, Row } from "react-bootstrap";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import { Link } from "react-router-dom";
import HeroMainTitle from "../../../Components/Common/HeroMainTitle";
import GetQuoteForm from "../../../Components/Common/GetQuoteForm";
import bgLogo from "../../../Assests/Images/Common/bg-logo.png";
import GetZarayeApp from "../../../Components/Common/getZarayeApp";
import "./index.css";
import LocalizationContext from "../../../Utils/LocalizationContext";

const GetAQuote = () => {
  const {t} = useContext(LocalizationContext)
  return (
    <div className="seo-landing-pg">
      <div className="landing-bg-logo position-absolute">
        <img src={bgLogo} loading="lazy" alt="bgLogo" />
      </div>
      <Container>
        <div className="breadcrumb">
          <span className="pe-1 unactive">
            <Link to={"/"}>{t('zaraye.marketplace.home.breadcrumbs')}</Link>
          </span>
          <span className="pe-1 greater">
            <img src={ArrowLeft} width={14} alt="greater than" loading="lazy" />
          </span>
          <span className="activelink">{t('zaraye.marketplace.getaquote.breadcrumbs')}</span>
        </div>
        <Row className="justify-content-between align-items-center">
          <div className="col-md-12 col-lg-6 mb-5 mb-lg-0">
            <HeroMainTitle
              topTagLine={t('zaraye.marketplace.seo.getaqote.tagline')}
              title={t('zaraye.marketplace.getfastefficientdelivery.text')}
              description={t('zaraye.marketplace.seo.zarayequote.description')}
              btnText={t('zaraye.marketplace.exploremarketplace.btntext')} 
              linkText={t('zaraye.marketplace.sourcingrediscovered.link')} />
          </div>
          <div className="col-lg-6 col-xl-5">
            <GetQuoteForm
              source={t("zaraye.marketplace.social.source")}
              formTitle={t("zaraye.marketplace.getaquote.form.text")}
              formDescription={t('zaraye.marketplace.zarayequote.form.description')}
            />
          </div>
        </Row>
      </Container>
      <GetZarayeApp />
    </div>
  );
};

export default GetAQuote;

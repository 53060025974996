import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import LandingPagesMainTitle from "../../../Components/Zaraye/LandingPagesTitle";
import SelectDropdown from "../../../Components/Common/selectDropdown";
import Input from "../../../Components/Common/input";
import Button from "../../../Components/Common/CustomBtn";
import RealTimePrice from "../../../Components/Common/RealTimePrice";
import { useSelector } from "react-redux";
import { useContext } from "react";
import LocalizationContext from "../../../Utils/LocalizationContext";
import "./style.css";

const RealTimePricePage = ({ item }) => {
  const industries = useSelector((state) => state.CommonReducer.industries);
  const loadAllRates = useSelector((state) => state.CommonReducer.allRates);
  const allBrands = useSelector((state) => state.CommonReducer.allBrands);
  const { t } = useContext(LocalizationContext);
  const [selectedIndustry, setSelectedIndustry] = useState({});
  const [selectedBrand, setSelectedBrand] = useState({});
  const [productName, setProductName] = useState("");
  const [ratesData, setRatesData] = useState(loadAllRates);
  const isRtl = document.documentElement.getAttribute("dir") === "rtl";

  const handleSelectIndustry = (selectedIndustry) => {
    setSelectedIndustry(selectedIndustry);
    setSelectedBrand({});
  };

  const handleSelectBrand = (selectedBrand) => {
    setSelectedBrand(selectedBrand);
  };

  const handleOnClickApplyFilter = () => {
    var data = loadAllRates;

    if (selectedIndustry?.value) {
      data = data.filter(
        (x) => x.IndustryId?.toString() === selectedIndustry?.value?.toString()
      );
    }

    if (selectedBrand?.value) {
      data = data.filter(
        (x) => x.BrandId?.toString() === selectedBrand?.value?.toString()
      );
    }

    if (productName) {
      data = data.filter((x) =>
        x.product?.toLowerCase().includes(productName.toLowerCase())
      );
    }

    setRatesData(data);
  };

  const quoteFormDropdownStyles = {
    menu: (provided, state) => ({
      ...provided,
      borderRadius: "8px", // Add border-radius
      border: "1px solid teal",
      marginLeft: "-10px",
    }),

    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),

    valueContainer: (provided) => ({
      ...provided,
      padding: "4px 8px",
    }),

    container: (provided, state) => ({
      ...provided,
      border: "1px solid teal",
      borderRadius: "40px",
      padding: "0 10px",
      background: "#ffffff42",
      marginTop: "0px",
      fontSize: "14px",
      fontFamily: isRtl ? "Gulzar" : "Regular",
      color: "#000",
    }),
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      background: "none",
      minHeight: "51px",
      border: "0",
      svg: {
        width: "17px",
        height: "50px",
      },
    }),

    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#808080",
      border: "0",
      padding: "0",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "teal" : "white",
      color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: "teal",
        color: "white",
        border: "2px solid",
      },
    }),
  };

  return (
    <>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <meta
          data-react-helmet="true"
          name="description"
          content={item?.MetaDescription ? item?.MetaDescription : ""}
        />
        <meta
          data-react-helmet="true"
          name="keywords"
          content={item?.MetaKeywords ? item?.MetaKeywords : ""}
        />
        <title>
          {item?.MetaTitle
            ? item?.MetaTitle
            : t("zaraye.marketplace.zarayerealtimeprice.metatitle")}
        </title>
        {item?.Slug && (
          <link
            rel="canonical"
            href={`http://app-react.zaraye.co/${item?.Slug}`}
          />
        )}
      </Helmet>
      <section className="industry-landing-page real-time-price-page">
        <Container className="mp-hero-container">
          <div className="breadcrumb">
            <span className="pe-1 unactive">
              <Link to={"/"}>{t("zaraye.marketplace.home.breadcrumbs")}</Link>
            </span>
            <span className="pe-1 greater">
              <img
                src={ArrowLeft}
                width={14}
                alt="greater than"
                loading="lazy"
              />
            </span>
            <span className="activelink">
              {t("zaraye.marketplace.realtimeprice.breadcrumbs")}
            </span>
          </div>
          <LandingPagesMainTitle
            headingText={t("zaraye.marketplace.priceindex.main.title")}
            descriptionOne={t("zaraye.marketplace.priceindex.main.description")}
          />
          <Row className="price-insights-filters my-4">
            <div className="col-sm-6 col-md-4 col-lg-3 mb-3 mb-lg-0">
              <Input
                className={"form-control"}
                placeholder={t("zaraye.marketplace.enteryourname.placeholder")}
                type="text"
                name={"name"}
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-3 mb-lg-0">
              <SelectDropdown
                isSearchable={true}
                isClearable={true}
                onChange={handleSelectIndustry}
                styles={quoteFormDropdownStyles}
                placeholder={t(
                  "zaraye.marketplace.realtime.table.industry.text"
                )}
                data={industries?.map((industry) => ({
                  value: industry?.Value,
                  label: industry?.Text,
                }))}
                value={selectedIndustry}
              />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-3 mb-lg-0">
              <SelectDropdown
                isSearchable={true}
                isClearable={true}
                styles={quoteFormDropdownStyles}
                placeholder={t("zaraye.marketplace.brands.title")}
                onChange={handleSelectBrand}
                data={
                  selectedIndustry?.value
                    ? allBrands
                        ?.filter(
                          (x) =>
                            x.IndustryId?.toString() ===
                            selectedIndustry?.value?.toString()
                        )
                        ?.map((item) => ({
                          value: item?.Id,
                          label: item?.BrandName,
                        }))
                    : allBrands.map((item) => ({
                        value: item?.Id,
                        label: item?.BrandName,
                      }))
                }
                value={selectedBrand}
              />
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 brand-filter-btn mb-3 mb-lg-0">
              <Button
                onClick={handleOnClickApplyFilter}
                className={"primary-btn w-100 text-center ripple"}
                text={t("zaraye.marketplace.applyfilter.text")}
              />
            </div>
          </Row>
          <Row className="mt-5 mb-5 mb-md-0">
            {ratesData?.length > 0 ? (
              <RealTimePrice data={ratesData} />
            ) : (
              <p className="no-data-found">
                {t("zaraye.marketplace.nodatafound.text")}
              </p>
            )}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default RealTimePricePage;

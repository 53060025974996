import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import HeadingDescription from "../SectionHeadingDescription";
import CloseIcon from "../../../Assests/Images/Common/close-dark.svg";
import Input from "../input";
import Button from "../CustomBtn";
import { Row } from "react-bootstrap";
import LocalizationContext from "../../../Utils/LocalizationContext";
import PhoneNumberInput from "../countryInput";
import { useDispatch, useSelector } from "react-redux";
import RequestService from "../../../Services/Request/RequestService";
import { toast } from "react-toastify";
import "./style.css";

const QuickRequestModal = (props) => {
  const { t } = useContext(LocalizationContext);
  const { onHide } = props;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberValidity, setPhoneNumberValidity] = useState(false);
  const dispatch = useDispatch();

  const handleOnClickSubmitRequest = async () => {
    if (name == "") {
      toast.error(t("zaraye.marketplace.pleaseenteryourname.error"));
    } else if (phoneNumber === "") {
      toast.error(t("zaraye.marketplace.pleaseenteryourphonenumber.error"));
    } else if (!phoneNumberValidity) {
      toast.error(t("zaraye.marketplace.pleaseenterphonenumber.error"));
    } else if (email === "") {
      toast.error(t("zaraye.marketplace.pleaseenteryouremail.error"));
    } else {
      var payload = {
        email: email,
        fullName: name,
        phone: phoneNumber,
      };
      var result = await RequestService.AddRequest(payload);
      if (result?.success) {
        // dispatch(shoppingCartAction.fetchShoppingCartItems());
        // navigate("/request-complete");
        setName("")
        setEmail("")
        setPhoneNumber("")
        toast.success("Quote Successfully Generated");
      } else {
        toast.error(result?.message);
      }
    }
  };

  return (
    <div className="quote-modal">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="p-0 main-form">
          <div className="d-flex justify-content-end modal-close">
            <img
              src={CloseIcon}
              alt="Close"
              width={16}
              className="cursor-pointer"
              onClick={onHide}
              loading="lazy"
            />
          </div>
          <Row className="justify-content-center">
            <div className="col-md-10 pt-4 pb-4">
              <div className="row align-items-center justify-content-center">
                <div className="col-md-9 request-headings">
                  <HeadingDescription
                    headingText={t(
                      "zaraye.marketplace.getyourinstantquote.text"
                    )}
                    descriptionOne={t(
                      "zaraye.marketplace.getyourinstantquote.description"
                    )}
                  />
                </div>
              </div>
              <div className="row align-items-center justify-content-center">
                <div className="col-md-9">
                  <div className="form-group">
                    <Input
                      className="form-control"
                      placeholder={t(
                        "zaraye.marketplace.enteryourname.placeholder"
                      )}
                      type="text"
                      name={"name"}
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="form-group">
                    <Input
                      className={"form-control"}
                      placeholder={t(
                        "zaraye.marketplace.emailaddress.placeholder"
                      )}
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      name={"Email Address"}
                    />
                  </div>
                </div>
                <div className="col-md-9">
                  <PhoneNumberInput
                    phoneNumber={phoneNumber}
                    onChangePhonenumber={(value) => {
                      setPhoneNumber(value);
                    }}
                    onPhoneNumberValidityChange={(validity) => {
                      setPhoneNumberValidity(validity);
                    }}
                  />
                </div>
                <div className="col-md-9">
                  <Button
                    className={"primary-btn w-100 text-center ripple"}
                    text={t("zaraye.marketplace.submitrequest.text")}
                    onClick={handleOnClickSubmitRequest}
                  />
                </div>
              </div>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default QuickRequestModal;

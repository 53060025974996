import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import hbdl from "../../../Assests/Images/Global/global-header-back-drop-logo.png";
import QuoteForm from "../../../Components/Global/GlobalQuoteForm";
import Video from "../../../Components/Common/Video";
import GlobalShipping from "../../../Assests/Images/Global/global-shipping.png";
import backDrop2 from "../../../Assests/Images/Global/before-footer-back-drop-logo.png";
import Button from "../../../Components/Common/CustomBtn";
import GetZarayeApp from "../../../Components/Common/getZarayeApp";
import Footer from "../../../Components/Global/Footer";
import OurCustomersSlider from "../../../Components/Common/OurCustomersSlider";
import { useNavigate } from 'react-router-dom';
import LocalizationContext from "../../../Utils/LocalizationContext";
import CustomButton from "../../../Components/Common/CustomBtn";
import "./index.css";

const HomePage = () => {
  const { t } = useContext(LocalizationContext);
  const navigate = useNavigate();
  const nextSectionRef = useRef(null);
  const scrollToNextSection = () => {
    if (nextSectionRef.current) {
      nextSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div className="global-hero-section">
      <div className="global-header-back-drop-logo">
        <img src={hbdl} alt={hbdl} loading="lazy" />
      </div>
        <div className="container z-1">
          <div className="row align-items-center justify-content-between">
            <div className={`col-md-8 col-lg-6 ${
                document.documentElement.dir === "rtl" ? "text-end" : "text-start"
              }`}>
              <div>
                <h1 className="welcome">{t('zaraye.marketplace.welcomezaraye.tagline')}</h1>
                <h1 className="main-heading">{t('zaraye.marketplace.revolutionizingglobalsourcing.text')} </h1>
                <p>{t('zaraye.marketplace.revolutionizingglobalsourcing.description')}</p>
                <div className="explore-box">
                  <CustomButton text={t('zaraye.marketplace.discoverproducts.text')} className='primary-btn' onClick={() => navigate('/product')} />
                  <p className="get-customize mb-0">
                    <a href="/"></a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link to="#myform">
          <div className="down" onClick={scrollToNextSection}>
            <div className="scroll-down">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </Link>
      </div>
      <div className="container quote-form-section" ref={nextSectionRef}>
        <div className="row">
          <div className="col-md-12" id="myform">
            <QuoteForm />
          </div>
        </div>
      </div>
      {/* about zaraye global section*/}
      <div className="container about-zaraye-global">
        <div className="row text-center">
          <div className="col-md-8 mx-auto">
            <h1 className="welcome">
              {t("zaraye.marketplace.aboutzarayeglobal.tagline")}
            </h1>
            <h1 className="main-heading">
              {t("zaraye.marketplace.empoweryourglobal.text")}
            </h1>
          </div>
          <div className="col-md-10 mx-auto">
            <p>{t("zaraye.marketplace.empoweryourglobal.description1")}</p>
            <p>{t("zaraye.marketplace.empoweryourglobal.description2")}</p>
            <p>{t("zaraye.marketplace.empoweryourglobal.description3")} </p>
          </div>
        </div>
      </div>
      {/* navigate the intricacies of international trade section */}
      <div className="container-fluid navigate-intricacies-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12 video-column">
              <Video />
            </div>
          </div>
          <div className="row navigate-intricacies-row">
            <div className="col-md-8 mx-auto">
              <h1 className="main-heading text-white">
                {t("zaraye.marketplace.facilitatingordersglobally.text")}
              </h1>
            </div>
            <div className="col-md-10 mx-auto">
              <p className="text-white">
                {t("zaraye.marketplace.facilitatingordersglobally.description")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row global-network-section">
          <div className="col-md-6">
            <img src={GlobalShipping} alt="GlobalShipping" loading="lazy" />
          </div>
          <div className="col-md-6">
            <h1 className="col-md-10 main-heading">
              {t("zaraye.marketplace.globalnetworkofshippingpartners.text")}
            </h1>
            <p>
              {t(
                "zaraye.marketplace.globalnetworkofshippingpartners.description"
              )}
            </p>
            <Button
              className="primary-btn"
              text={t("zaraye.marketplace.startdiscovering.text")}
            />
          </div>
        </div>
      </div>
      {/* Hear What Our Customers Say Section */}
      <div className="mp-home">
        <OurCustomersSlider />
      </div>
      {/* Navigate Global Commerce with Ease: The Zaraye Global App */}
      <div className="container-fluid navigate-global-section px-0">
        <GetZarayeApp />
        <div className="before-footer-back-drop-logo">
          <img src={backDrop2} alt={backDrop2} loading="lazy" />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;

import React, { useContext } from 'react'
import { Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LandingPagesMainTitle from '../../../Components/Zaraye/LandingPagesTitle'
import LocalizationContext from '../../../Utils/LocalizationContext'

const ThankYouPage = () => {
    const {t} =useContext(LocalizationContext)
    return (
        <Container>
            <Row>
            <LandingPagesMainTitle headingText={t('zaraye.marketplace.zarayesolutiion.text')} descriptionOne={t('zaraye.marketplace.zarayesolutiion.description')} />
            </Row>
            <div className="breadcrumb">
                <span className="pe-1 unactive">
                <Link to={"/"}>{t('zaraye.marketplace.home.breadcrumbs')}</Link>
                </span>
                <span className="pe-1 greater">
                    {/* <img src={ArrowLeft} width={14} alt="greater than" /> */}
                </span>
                <span className="pe-1 unactive">
                    <Link to>{t('zaraye.marketplace.brands.breadcrumbs')}</Link>
                </span>
                <span className="pe-1 greater">
                    {/* <img src={ArrowLeft} width={14} alt="greater than" /> */}
                </span>
                <span className="activelink">
                    {/* {brandData?.Name} */}
                </span>
            </div>
        </Container>
    )
}

export default ThankYouPage

import React, { useState, useContext } from "react";
import RealTimePrice from "../../../Components/Common/RealTimePrice";
import OurProducts from "../../../Components/Common/Products";
import ManufacturesAndBrands from "../../../Components/Common/ManufacturesAndBrands";
import OurCustomersSlider from "../../../Components/Common/OurCustomersSlider";
import HeroSection from "../../../Components/Marketplace/HeroSection";
import MarketNews from "../../../Components/Marketplace/MarketNews";
import OurCategories from "../../../Components/Common/OurCategories";
import GetZarayeApp from "../../../Components/Common/getZarayeApp";
import HeadingDescription from "../../../Components/Common/SectionHeadingDescription";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Row } from "react-bootstrap";
import CustomButton from "../../../Components/Common/CustomBtn";
import { useNavigate } from "react-router-dom";
import RequestAQuote from "../../../Components/Common/RequestModal";
import LocalizationContext from "../../../Utils/LocalizationContext";
import "./index.css";

const HomePage = () => {
  const loadAllRates = useSelector((state) => state.CommonReducer.allRates);
  const products = useSelector(
    (state) => state.CommonReducer.industriesAndCategories
  );
  const bestSellingProducts = useSelector(
    (state) => state.CommonReducer.allBestSellingProducts
  );
  const productData = useSelector((state) => state.CommonReducer.products);
  const allBrands = useSelector((state) => state.CommonReducer.allBrands);
  const [modalShow, setModalShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const popularProducts = productData?.Products?.slice(0, 10);
  const navigate = useNavigate();
  const { t } = useContext(LocalizationContext);

  return (
    <>
      <Helmet>
        <meta name="description" content={""} />
        <meta name="keywords" content={""} />
        <title>{t("zaraye.marketplace.zaraye.home.metatitle")}</title>
        <link rel="canonical" href={`https://www.react-app.zaraye.co/`} />
      </Helmet>
      <div className="mp-home">
        <HeroSection />
        <div className="explore-product-section">
          <div className="container">
            <div className="row">
              <div className={`col-md-12 col-xl-9 explore-prod-left-column text-center ${
                  document.documentElement.dir === "rtl" ? "text-md-end" : "text-md-start"
                }`}>
                <HeadingDescription
                  headingText={t(
                    "zaraye.marketplace.getrealtimeproductrates.text"
                  )}
                  descriptionOne={t(
                    "zaraye.marketplace.getrealtimeproductrates.description"
                  )}
                />
              </div>
              {loadAllRates?.length > 0 && (
                <RealTimePrice data={loadAllRates} showItemsCount={5} />
              )}
            </div>
          </div>
        </div>
        {bestSellingProducts?.length > 0 && (
          <OurProducts
            data={bestSellingProducts}
            headingText={t("zaraye.marketplace.topsellingproducts.text")}
            descriptionOne={t(
              "zaraye.marketplace.topsellingproducts.description"
            )}
            linkText={t("zaraye.marketplace.getaquote.text")}
          />
        )}
        <OurCategories
          linkText={t("zaraye.marketplace.explorecategories.linktext")}
          headingText={t("zaraye.marketplace.exploreallcategories.text")}
          descriptionOne={t("zaraye.marketplace.explorecategories.description")}
        />
        <section className="position-relative">
          <ManufacturesAndBrands data={allBrands} />
        </section>

        <Container className="popular-products-section">
          <Row className="justify-content-between">
            <div className={`col-md-8 text-center ${document.documentElement.dir === "rtl" ? "text-md-end" : "text-md-start"}`}>
              <HeadingDescription
                headingText={t("zaraye.marketplace.topratedproducts.text")}
                descriptionOne={t(
                  "zaraye.marketplace.topratedproducts.description"
                )}
              />
            </div>
            <div className="col-md-4 pe-0 text-end d-none d-md-block rtl-popular">
              <Link to={"/product"} className="view-all">
                {t("zaraye.marketplace.viewallproducts.text")}
              </Link>
            </div>
          </Row>
          <Row>
            <div className="col-md-12 d-flex flex-wrap grid-product position-relative">
              {popularProducts?.map((item, index) => (
                <div
                  key={`popular-products-${index}`}
                  className="card products"
                >
                  <Link
                    to={`/${item.SeName}`}
                    className="text-decoration-none inner-products"
                  >
                    <div className="position-relative prod-box-height cat-thumbnail">
                      <img
                        src={item.Picture}
                        alt={item.Name}
                        className="w-100 cat-img-fix"
                        loading="lazy"
                      />
                      <div className="overlay">
                        <div className="buttons">
                          <CustomButton
                            text={t("zaraye.marketplace.addtocart.text")}
                            className="secondary-btn"
                            onClick={() => navigate(`/${item.SeName}`)}
                          />
                        </div>
                      </div>
                    </div>
                  </Link>
                  <div className="card-body d-flex flex-column justify-content-between">
                    <Link
                      to={`/${item.SeName}`}
                      className="text-decoration-none"
                    >
                      <h5 className="mb-0 mt-1">{item?.Name}</h5>
                    </Link>
                    <h6
                      className="mt-2"
                      onClick={() => {
                        setSelectedProduct(item);
                        setModalShow(true);
                      }}
                    >
                      {t("zaraye.marketplace.getaquote.text")}
                    </h6>
                  </div>
                </div>
              ))}
              <div className="w-100 all-products-btn">
                <CustomButton
                  text={t("zaraye.marketplace.viewallproducts.text")}
                  className="primary-btn"
                  onClick={() => navigate("/product")}
                />
              </div>
              <RequestAQuote
                show={modalShow}
                onHide={() => setModalShow(false)}
                productimage={selectedProduct?.Picture}
                productname={selectedProduct?.Name}
              />
            </div>
          </Row>
        </Container>

        <div className="container market-news-section">
          <div className="row d-flex justify-content-between heading-bb">
            <div className="col-sm-12 col-md-10 col-xl-10 ps-0">
              <HeadingDescription
                headingText={t("zaraye.marketplace.latestnewsandupdates.text")}
                descriptionOne={t(
                  "zaraye.marketplace.latestnewsandupdates.description"
                )}
              />
            </div>
            <div className="col-md-2 col-xl-2 pe-0 text-end d-none d-lg-block rtl-popular">
              <Link to={"/blogs"} className="view-all">
                {t("zaraye.marketplace.viewourblogs.link")}
              </Link>
            </div>
          </div>
          <MarketNews />
        </div>
        <OurCustomersSlider />
        <GetZarayeApp />
      </div>
    </>
  );
};

export default HomePage;

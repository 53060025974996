import { Helmet } from "react-helmet";
import { useContext, useEffect, useState } from "react";
import CommonService from "../../../Services/Common/CommonService";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import HeadingDescription from "../../../Components/Common/SectionHeadingDescription";
import Button from "../../../Components/Common/CustomBtn";
import GetZarayeApp from "../../../Components/Common/getZarayeApp";
import ProductsGridItem from "../../../Components/Common/ProductsGridItem";
import LocalizationContext from "../../../Utils/LocalizationContext";
import { useSelector } from "react-redux";
import "./index.css";

const CategoryDetailPage = ({ item }) => {
  const { t } = useContext(LocalizationContext);
  const languageId = useSelector((state) => state.CommonReducer?.languageId);

  const [categoryData, setCategoryData] = useState({});
  const getCategoryDetail = async (id) => {
    var response = await CommonService.GetCategoryById(id);
    if (response?.success) {
      setCategoryData(response?.data);
    }
  };

  useEffect(() => {
    getCategoryDetail(item?.Id);
  }, [item?.Id, languageId]);

  return (
    <div className="mp-home">
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <meta
          data-react-helmet="true"
          name="description"
          content={item?.MetaDescription ? item?.MetaDescription : ""}
        />
        <meta
          data-react-helmet="true"
          name="keywords"
          content={item?.MetaKeywords ? item?.MetaKeywords : ""}
        />
        <title>
          {item?.MetaTitle
            ? item?.MetaTitle
            : t("zaraye.marketplace.zarayeb2brawmaterialplatform.metatitle")}
        </title>
        {item?.Slug && (
          <link
            rel="canonical"
            href={`https://www.react-app.zaraye.co/${item?.Title}`}
          />
        )}
      </Helmet>
      <Container>
        <div className="breadcrumb">
          <span className="pe-1 unactive">
            <Link to={"/"}>{t("zaraye.marketplace.home.breadcrumbs")}</Link>
          </span>
          <span className="pe-1 greater">
            <img src={ArrowLeft} width={14} alt="greater than" />
          </span>
          <span className="pe-1 unactive">
            <Link to>{t("zaraye.marketplace.categories.breadcrumbs")}</Link>
          </span>
          <span className="pe-1 greater">
            <img src={ArrowLeft} width={14} alt="greater than" />
          </span>
          <span className="activelink">{item?.Title}</span>
        </div>

        <div className="all-brands-main">
          <div className="d-flex flex-column align-items-center justify-content-center sub-header-container">
            <div className="cat-dtl-img">
              <img
                src={categoryData?.Picture}
                alt={categoryData?.Name}
                loading="lazy"
                className="w-100 cat-dtl-img-fix"
              />
            </div>
            <div className="row">
              <div className="col-md-9 mx-auto">
                <p
                  className="mt-3 text-center"
                  dangerouslySetInnerHTML={{
                    __html: categoryData?.ShortDescription,
                  }}
                ></p>
              </div>
            </div>
          </div>
          <div className="popular-products-section">
            <div className="row flex-wrap grid-product brand-detail-products">
              <ProductsGridItem data={categoryData} />
            </div>
          </div>

          <div
            className="row brand-detail-section justify-content-between align-items-center"
            style={{ display: categoryData?.Description ? "flex" : "none" }}
          >
            <div className="col-md-6 bd-long-desc">
              <HeadingDescription
                headingText={`${t("zaraye.marketplace.learnmoreabout.text")} ${
                  categoryData?.Name
                }`}
                descriptionOne={categoryData?.Description}
              />
              <Button
                className={"primary-btn text-center ripple"}
                text={t("zaraye.marketplace.customquote.text")}
              />
            </div>
            <div className="col-md-5 d-flex justify-content-end align-items-center">
              <img
                src={categoryData?.Picture}
                alt={categoryData?.Name}
                loading="lazy"
                className="img-fluid w-100"
              />
            </div>
          </div>
          <GetZarayeApp />
        </div>
      </Container>
    </div>
  );
};

export default CategoryDetailPage;

import React, { useRef } from "react";
import "./style.css";

const CustomAccordion = (props) => {
  const content = useRef();
  const { handleToggle, active, question, answer } = props;

  return (
    <div className="accordion-card">
      <div className="accordion-header">
        <div
          className={`accordion-toggle ${active === question  ? "active" : ""}`}
          onClick={() => handleToggle(question )}
        >
          <span>.</span>
          <h5 className="accordion-title">{question}</h5>
          <span
            className={`accordion-icon ${active === question  ? "open" : "close"}`}
          ></span>
        </div>
      </div>
      <div
        ref={content}
        className={`collapsed ${active === question  ? "show" : ""}`}
        style={
          active === question 
            ? { height: content.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <div className="accordion-body">
          <p className="mb-0">{answer}</p>
        </div>
      </div>
    </div>
  );
};

export default CustomAccordion;

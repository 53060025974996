const CustomButton = ({ className, text, onClick, disabled, icon }) => {
  return (
    <button className={`${className}`} onClick={onClick} disabled={disabled}>
      {icon && (
        <img
          src={icon}
          alt={icon}
          loading="lazy"
          className={`${document.documentElement.dir === "rtl" ? "ms-2 me-0" : "me-2"}`}
        />
      )}
      {text}
    </button>
  );
};

export default CustomButton;

import React, { useContext } from "react";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import HeadingDescription from "../../../Components/Common/SectionHeadingDescription";
import MobileTracking from "../../../Assests/Images/Common/mobile-tracking-screen.png";
import CustomButton from "../../../Components/Common/CustomBtn";
import LocalizationContext from "../../../Utils/LocalizationContext";
import "./style.css";

const RequestCompletePage = () => {
  const { t } = useContext(LocalizationContext)
  const navigate = useNavigate();

  return (
    <Container className="thankyou-page">
      <div className="breadcrumb">
        <span className="pe-1 unactive">
          <Link to={"/"}>{t('zaraye.marketplace.home.breadcrumbs')}</Link>
        </span>
        <span className="pe-1 greater">
          <img src={ArrowLeft} width={14} alt="greater than" loading="lazy" />
        </span>
        <span className="pe-1 unactive">
          <Link to={"/"}>{t('zaraye.marketplace.product.breadcrumbs')}</Link>
        </span>
        <span className="pe-1 greater">
          <img src={ArrowLeft} width={14} alt="greater than" loading="lazy" />
        </span>
        <span className="pe-1 unactive">
          <Link to={"/cart"}>{t('zaraye.marketplace.cart.breadcrumbs')}</Link>
        </span>
        <span className="pe-1 greater">
          <img src={ArrowLeft} width={14} alt="greater than" loading="lazy" />
        </span>
        <span className="activelink">{t('zaraye.marketplace.requestcomplete.breadcrumbs')}</span>
      </div>
      <Row className="justify-content-center">
        <div className="col-md-10 col-lg-8 col-xl-6 pt-0 pt-sm-4 d-flex flex-column align-items-center justify-content-center">
          <div className="text-center pb-3">
            <HeadingDescription
              headingText={t('zaraye.marketplace.requestedcomplete.text')}
              descriptionOne={t('zaraye.marketplace.requestedcomplete.description')}
            />
          </div>
          <img
            src={MobileTracking}
            alt={MobileTracking}
            loading="lazy"
            className="pt-5 mobile-tracking"
          />
          <CustomButton
            className="primary-btn go-to-marketplace"
            text={t('zaraye.marketplace.backtomarketplace.text')}
            onClick={() => navigate("/")}
          />
        </div>
      </Row>
    </Container>
  );
};

export default RequestCompletePage;

import React, { useState, useEffect, useRef, useContext } from "react";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import LocalizationContext from "../../Utils/LocalizationContext";

function getRandom(min, max) {
  const floatRandom = Math.random();

  const difference = max - min;

  // random between 0 and the difference
  const random = Math.round(difference * floatRandom);

  const randomWithinRange = random + min;

  return randomWithinRange;
}

const PhoneNumberInput = ({
  phoneNumber,
  onChangePhonenumber,
  onPhoneNumberValidityChange, // Add this prop
  id = getRandom(999, 9999),
}) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [isValid, setIsValid] = useState(false);
  const itiRef = useRef(null);
  const { t } = useContext(LocalizationContext)

  const handleDragStart = (e) => {
    e.preventDefault();
  };

  const handleCountryChange = () => {
    const selectedData = itiRef.current.getSelectedCountryData();
    setSelectedCountryCode(selectedData.dialCode);
    if (onChangePhonenumber) {
      onChangePhonenumber(`+${selectedData.dialCode}${phoneNumber}`);
    }
  };

  useEffect(() => {
    const input = document.querySelector(`#phoneNumber${id}`);
    const iti = intlTelInput(input, {
      initialCountry: "pk",
      separateDialCode: true,
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });
    itiRef.current = iti;
    setSelectedCountryCode(iti.getSelectedCountryData().dialCode);
    input.addEventListener("countrychange", handleCountryChange);
    
    input.addEventListener("keyup", () => {
      setIsValid(iti.isValidNumber());
      if (onPhoneNumberValidityChange) {
        onPhoneNumberValidityChange(iti.isValidNumber());
      }
    });

    return () => {
      iti.destroy(); // Clean up the intlTelInput instance when component unmounts
    };
  }, []);

  return (
    <div className="form-group position-relative custom-number-input">
      <input
        className={`form-control ${phoneNumber.replace("+" + selectedCountryCode, "") != "" && isValid == false ? 'invalid-phone' : ""}`}
        placeholder={t('zaraye.marketplace.phonenumber.placeholder')}
        type="tel"
        autoComplete="off"
        value={phoneNumber.replace("+" + selectedCountryCode, "")}
        id={`phoneNumber${id}`}
        maxLength={20}
        onDragStart={handleDragStart}
        onDrop={handleDragStart}
        onDragOver={handleDragStart}
        onDragEnter={handleDragStart}
        onChange={(e) => {
          const inputValue = e.target.value;
          const numericInputValue = inputValue.replace(/\D/g, '');
          if (onChangePhonenumber) {
            onChangePhonenumber(`+${selectedCountryCode}${numericInputValue}`);
          }
        }}
      />
    </div>
  );
};

export default PhoneNumberInput;

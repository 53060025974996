// import React from "react";
import React, { useEffect } from 'react';
import Loading from "../../../Assests/Images/Common/loading.gif"
import "./style.css"

const Loader = () => {
  // useEffect(() => {
  //   document.body.style.overflow = 'hidden';
  //   return () => {
  //     document.body.style.overflowY = 'auto';
  //   };
  // }, []);

  return (
    <div className='d-flex justify-content-center align-items-center loading-body'>
      <img src={Loading} alt="" loading="lazy" />
    </div>
  );
};

export default Loader;

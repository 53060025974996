import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import CustomButton from "../CustomBtn";
import FilterDropdown from "../Filter";
import { Container, Row } from "react-bootstrap";
import RequestAQuote from "../RequestModal";
import "./index.css";
import LocalizationContext from "../../../Utils/LocalizationContext";

const ProductsGridItem = ({ data = {} }) => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});

  const { t } = useContext(LocalizationContext);

  const pageSize = [
    {
      Name: "5",
    },
    {
      Name: "10",
    },
    {
      Name: "15",
    },
    {
      Name: "20",
    },
  ];

  return (
    <Container>
      <Row className="grid-filter-row">
        <div className="col-md-12 d-flex justify-content-between align-items-center px-0 px-md-3">
          <div className="grid-layout d-flex align-items-center">
            <div className="filter-page-size">
              <p className="text-dark mb-0">
                {t("zaraye.marketplace.showing.text")}
                <span className="fw-bold"> {data?.Products?.length} </span>
                {t("zaraye.marketplace.itemsoftotal.text")}
                <span className="fw-bold"> {data?.TotalCount} </span>
                {t("zaraye.marketplace.items.text")}
              </p>
            </div>
          </div>
          <div className="sorting d-none">
            <FilterDropdown
              title={t("zaraye.marketplace.featuredlabel.text")}
              pageSize={pageSize}
            />
          </div>
        </div>
      </Row>
      <Row>
        <div className="col-md-12 d-flex flex-wrap grid-product">
          {data?.Products?.map((item, index) => (
            <div key={`products-${index}`} className="card products">
              <Link
                to={`/${item.SeName}`}
                className="text-decoration-none inner-products"
              >
                <div className="position-relative prod-box-height cat-thumbnail">
                  <img
                    src={item?.Picture}
                    alt={item?.Name}
                    className="w-100 cat-img-fix"
                    loading="lazy"
                  />
                  <div className="overlay">
                    <div className="buttons">
                      <CustomButton
                        text={t("zaraye.marketplace.addtocart.text")}
                        className="secondary-btn"
                      />
                    </div>
                  </div>
                </div>
              </Link>
              <div className="card-body d-flex flex-column justify-content-between">
                <Link to={`/${item.SeName}`} className="text-decoration-none">
                  <h5 className="mb-0 mt-1">{item?.Name}</h5>
                </Link>
                <h6
                  className="mt-2"
                  onClick={() => {
                    setSelectedProduct(item);
                    setModalShow(true);
                  }}
                >
                  {t("zaraye.marketplace.getaquote.form.text")}
                </h6>
              </div>
            </div>
          ))}
          <RequestAQuote
            show={modalShow}
            onHide={() => setModalShow(false)}
            productimage={selectedProduct?.Picture}
            productname={selectedProduct?.Name}
          />
        </div>
      </Row>
    </Container>
  );
};

export default ProductsGridItem;

import React, { useContext, useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import CustomCollapse from "../../../Components/Common/CustomCollapse";
import { useSelector, useDispatch } from "react-redux";
import ProductsGridItem from "../../../Components/Common/ProductsGridItem";
import * as commonAction from "../../../Actions/Common/CommonAction";
import ArrowLeft from "../../../Assests/Images/Common/arrow-back.png";
import Pagination from "../../../Components/Common/Pagination";
import CloseIcon from "../../../Assests/Images/Common/close-dark.svg";
import FilterIcon from "../../../Assests/Images/Common/filter.svg";
import LocalizationContext from "../../../Utils/LocalizationContext";
import "./index.css";

const ProductPage = ({ item }) => {
  const { t } = useContext(LocalizationContext);
  const dispatch = useDispatch();
  const location = useLocation();

  const productFilters = useSelector((state) => state.CommonReducer.productFilters);
  const productData = useSelector((state) => state.CommonReducer.products);
  const [industryFilters, setIndustryFilters] = useState(productFilters?.Industries);
  const [categotyFilters, setCategotyFilters] = useState(productFilters?.Categories);
  const [brandFilters, setBrandFilters] = useState(productFilters?.Brands);
  const [pageIndex, setPageIndex] = useState(productData?.PageIndex);
  const [isRunning, setIsRunning] = useState(true);
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
    document.body.style.overflow = isFilterVisible ? "auto" : "hidden";
  };

  useEffect(() => {
    setIndustryFilters(productFilters?.Industries);
    setCategotyFilters(productFilters?.Categories);
    setBrandFilters(productFilters?.Brands);
  }, [productFilters]);

  useEffect(() => {
    if (!isRunning) {
      applyFilters();
    }
  }, [industryFilters, categotyFilters, brandFilters, pageIndex]);

  useEffect(() => {
    if (location?.state?.industryId) {
      var industryData = Object.assign([], industryFilters);
      industryData?.map((x) => {
        x.Checked = x?.Id === location?.state?.industryId;
        setIsRunning(false);
        setPageIndex(0);
        setIndustryFilters(industryData);
      });
    }
  }, [location?.state?.industryId]);

  const handleOnChangeIndustryFilter = (id, checked) => {
    var industryData = Object.assign([], industryFilters);
    industryData.find((x) => x.Id === id).Checked = checked;
    setIsRunning(false);
    setPageIndex(0);
    setIndustryFilters(industryData);
  };

  const handleOnChangeCategoryFilter = (id, checked) => {
    var categoryData = Object.assign([], categotyFilters);
    categoryData.find((x) => x.Id === id).Checked = checked;
    setIsRunning(false);
    setPageIndex(0);
    setCategotyFilters(categoryData);
  };

  const handleOnChangeBrandFilter = (id, checked) => {
    var brandData = Object.assign([], brandFilters);
    brandData.find((x) => x.Id === id).Checked = checked;
    setIsRunning(false);
    setPageIndex(0);
    setBrandFilters(brandData);
  };

  const applyFilters = () => {
    var industryIds = industryFilters
      ?.filter((x) => x?.Checked === true)
      ?.map((x) => x.Id)
      ?.join(",");
    var categoryIds = categotyFilters
      ?.filter((x) => x?.Checked === true)
      ?.map((x) => x.Id)
      ?.join(",");
    var brandIds = brandFilters
      ?.filter((x) => x?.Checked === true)
      ?.map((x) => x.Id)
      ?.join(",");

    dispatch(
      commonAction.fetchProducts(
        industryIds,
        categoryIds,
        brandIds,
        12,
        pageIndex,
        ""
      )
    );
  };

  const handlePageChange = (value) => {
    setIsRunning(false);
    setPageIndex(value);
  };

  return (
    <Container className="product-list-pg o-h">
      <div className="breadcrumb">
        <span className="pe-1 unactive">
          <Link to={"/"}>{t("zaraye.marketplace.home.breadcrumbs")}</Link>
        </span>
        <span className="pe-1 greater">
          <img src={ArrowLeft} width={14} alt="greater than" loading="lazy" />
        </span>
        <span className="activelink">
          {t("zaraye.marketplace.products.breadcrumbs")}
        </span>
      </div>
      <Row>
        <div className={`col-md-4 col-lg-3 product-filter-column ${isFilterVisible ? "show" : ""}`}>
          <div className={`mobile-filter-header ${isFilterVisible ? "d-block" : "d-none"}`}>
            <h2>{t("zaraye.marketplace.filter.text")}</h2>
            <button className="close-filter" onClick={toggleFilterVisibility}>
              {" "}
              {t("zaraye.marketplace.close.text")}
              <img
                src={CloseIcon}
                alt="Close"
                width={12}
                loading="lazy"
                className="ms-1"
              />
            </button>
          </div>

          {/* <div className={`col-md-3 product-filter-column ${isColumnVisible ? 'show' : 'hidden'}`}> */}
          <div className="filter-sidebar filter-cont h-100">
            <CustomCollapse
              title={t("zaraye.marketplace.industries.title")}
              data={industryFilters}
              onChangeFilter={handleOnChangeIndustryFilter}
            />
            <CustomCollapse
              title={t("zaraye.marketplace.allcategories.title")}
              data={
                industryFilters?.filter((x) => x?.Checked === true).length > 0
                  ? categotyFilters?.filter((x) =>
                    industryFilters
                      ?.filter((x) => x?.Checked === true)
                      ?.map((x) => x.Id)
                      ?.includes(x.ParentId)
                  )
                  : categotyFilters
              }
              onChangeFilter={handleOnChangeCategoryFilter}
            />
            <CustomCollapse
              title={t("zaraye.marketplace.brands.title")}
              data={
                industryFilters?.filter((x) => x?.Checked === true).length > 0
                  ? brandFilters?.filter((x) =>
                    industryFilters
                      ?.filter((x) => x?.Checked === true)
                      ?.map((x) => x.Id)
                      ?.includes(x.ParentId)
                  )
                  : brandFilters
              }
              onChangeFilter={handleOnChangeBrandFilter}
            />
          </div>
        </div>
        <div className="col-md-8 col-lg-9 position-relative">
          <button
            onClick={toggleFilterVisibility}
            className="d-flex d-md-none align-items-center mobile-filter-btn"
          >
            <img
              src={FilterIcon}
              alt="Close"
              className={`${document.documentElement.dir === "rtl" ? "ps-2" : "me-1"}`}
            />
            {t("zaraye.marketplace.filter.text")}
          </button>
          <ProductsGridItem data={productData} />
        </div>
      </Row>
      {productData?.TotalPages > 1 && (
        <div className="pagination-box pe-0 pe-md-4">
          <Pagination
            HasNextPage={productData?.HasNextPage}
            HasPreviousPage={productData?.HasPreviousPage}
            currentPage={pageIndex}
            totalPages={productData?.TotalPages}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </Container>
  );
};

export default ProductPage;
